import { useState, useEffect, useContext, useCallback } from "react";

// Redux
import { useSelector } from "react-redux";

// API
import {
  getTemplates,
  getTemplate,
  patchTemplate,
  postTemplate,
} from "../../api/eds/templates";
import {
  getParameterBlacklist,
  postParameterBlacklist,
  deleteParameterBlacklist,
} from "../../api/eds/parameterBlacklist";

// Context
import { DetailsContext } from "../../contexts/detailsContext";

// Hooks
import useGetParameterGroups from "../../hooks/useGetParameterGroups";
import useGetSystems from "../../hooks/useGetSystems";

// API
import { updateStatus } from "../../api/ddb/parameterService";

const useGrouping = () => {
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const detailsContext = useContext(DetailsContext);
  const setCurrentParameter = detailsContext.setCurrentParameter;
  const setCurrentParameterGroups = detailsContext.setCurrentParameterGroups;
  const currentParameterGroups = detailsContext.currentParameterGroups;
  const selectedTemplate = detailsContext.selectedTemplate;
  const setSelectedTemplate = detailsContext.setSelectedTemplate;
  const activeSocketUsers = detailsContext.activeSocketUsers;

  const setCurrentOpenGroup = detailsContext.setCurrentOpenGroup;
  const currentOpenGroup = detailsContext.currentOpenGroup;

  const [templates, setTemplates] = useState([]);
  const [hasExistingTemplate, setHasExistingTemplate] = useState(false);
  const [selectedCell, setSelectedCell] = useState({});
  const [parameterBlacklist, setParameterBlacklist] = useState([]);
  const [parameterGroupsLoading, setParameterGroupsLoading] = useState(true);
  const [usersForThisSubsystem, setUsersForThisSubsystem] = useState([]);

  const { getParameterGroups } = useGetParameterGroups();

  useEffect(() => {
    const users = activeSocketUsers.filter((item) => {
      return item.currentSubSystemId === currentSubSystem?.id;
    });
    setUsersForThisSubsystem(users);
  }, [activeSocketUsers, currentSubSystem]);

  const getAllTemplates = useCallback(async () => {
    const newTemplates = await getTemplates();
    setTemplates(newTemplates.data);
    try {
      const template = await getTemplate(
        currentSubSystem.id,
        currentProject.project_id
      );
      setSelectedTemplate(template.data.template);
      setHasExistingTemplate(true);
    } catch {
      setSelectedTemplate();
    }
  }, [currentProject, currentSubSystem, setSelectedTemplate]);

  const setTemplate = async () => {
    if (hasExistingTemplate) {
      await patchTemplate(
        currentSubSystem.id,
        currentProject.project_id,
        selectedTemplate.id
      );
    } else {
      await postTemplate(
        currentSubSystem.id,
        currentProject.project_id,
        selectedTemplate.id
      );
    }
  };

  const toggleGroupOpen = (id) => {
    setCurrentParameter();
    setSelectedCell();
    setCurrentOpenGroup(id === currentOpenGroup ? null : id);
  };

  const clickParameterCell = (parameter) => {
    const newCurrent = { id: parameter.id };
    setSelectedCell(newCurrent);
    setCurrentParameter(parameter);
  };

  const [addParameterOpen, setAddParameterOpen] = useState(false);
  const onClose = () => {
    setAddParameterOpen(false);
  };
  const setCurrentAsset = detailsContext.setCurrentAsset;
  const setCurrentParameterType = detailsContext.setCurrentParameterType;

  const clickEmptyCell = (parameterType, asset) => {
    setCurrentParameterType(parameterType);
    setCurrentAsset(asset);
    setAddParameterOpen(true);
  };

  const checkPercentagesAddUpTo100 = (percentageCounts) => {
    const totalPercentage = percentageCounts.reduce(
      (total, item) => total + item.percentage,
      0
    );

    if (isNaN(totalPercentage) || totalPercentage === 100) return;

    const difference = Math.abs(100 - totalPercentage);
    const index = percentageCounts.findIndex(
      (status) => status.percentage > difference
    );
    if (totalPercentage > 100) {
      percentageCounts[index].percentage -= difference;
    } else {
      percentageCounts[index].percentage += difference;
    }
  };

  const getGroupHeaderStatus = (group) => {
    let parameterGroupStatus = [];
    group.eds_parameter_types.forEach((parameterType) => {
      const parameterStatus = [];
      parameterType.parameters.forEach((parameter) => {
        if (!("name" in parameter)) {
          parameterStatus.push(parameter.revision?.status);
        } else {
          parameterStatus.push("unanswered");
        }
      });
      parameterGroupStatus = parameterGroupStatus.concat(parameterStatus);
    });

    let parameterSubGroupStatus = [];
    group.sub_groups.forEach((subGroup) => {
      let subGroupStatus = [];
      subGroup.eds_parameter_types.forEach((parameterType) => {
        const parameterStatus = [];
        parameterType.parameters.forEach((parameter) => {
          if (!("name" in parameter)) {
            parameterStatus.push(parameter.revision?.status);
          } else {
            parameterStatus.push("unanswered");
          }
        });
        subGroupStatus = subGroupStatus.concat(parameterStatus);
      });
      parameterSubGroupStatus = parameterSubGroupStatus.concat(subGroupStatus);
    });

    const allStatus = parameterGroupStatus.concat(parameterSubGroupStatus);

    const counts = [
      {
        status: "unanswered",
        color: "secondary.main",
        count: allStatus.filter((item) => item === "unanswered").length,
      },
      {
        status: "rejected",
        color: "error.main",
        count: allStatus.filter((item) => item === "rejected").length,
      },
      {
        status: "answered",
        color: "error.main",
        count: allStatus.filter((item) => item === "answered").length,
      },
      {
        status: "checked",
        color: "warning.main",
        count: allStatus.filter((item) => item === "checked").length,
      },
      {
        status: "approved",
        color: "success.main",
        count: allStatus.filter((item) => item === "approved").length,
      },
    ];

    let percentageCounts = counts.map((item) => {
      let percentage = Math.round((item.count / allStatus.length) * 100);
      return { ...item, percentage };
    });

    checkPercentagesAddUpTo100(percentageCounts);

    return percentageCounts;
  };

  const checkApproveParameterGroup = async (oldStatus, targetStatus, group) => {
    const newGroups = currentParameterGroups.map((mapGroup) => {
      if (mapGroup.id === group.id) {
        mapGroup.massLoading = true;
      }
      return mapGroup;
    });
    setCurrentParameterGroups(newGroups);
    setCurrentParameter();
    let parameterGroup = [];

    group.eds_parameter_types.forEach((parameterType) => {
      const parameters = [];
      parameterType.parameters.forEach((parameter) => {
        if (
          !("name" in parameter) &&
          parameter.revision?.status === oldStatus
        ) {
          parameters.push(parameter);
        }
      });
      parameterGroup = parameterGroup.concat(parameters);
    });

    let parameterSubGroup = [];
    group.sub_groups.forEach((subGroup) => {
      let subGroups = [];
      subGroup.eds_parameter_types.forEach((parameterType) => {
        const parameters = [];
        parameterType.parameters.forEach((parameter) => {
          if (
            !("name" in parameter) &&
            parameter.revision?.status === oldStatus
          ) {
            parameters.push(parameter);
          }
        });
        subGroups = subGroups.concat(parameters);
      });
      parameterSubGroup = parameterSubGroup.concat(subGroups);
    });

    const allParameters = parameterGroup.concat(parameterSubGroup);
    Promise.all(
      allParameters.map(async (parameter) => {
        const res = await updateStatus(parameter.id, targetStatus);
        return res;
      })
    ).then(async (res) => {
      if (res[0].status === 200) {
        getParameterGroups();
      }
    });
  };

  const getBlacklist = useCallback(async () => {
    if (currentSubSystem) {
      const newBlacklist = await getParameterBlacklist(currentSubSystem.id);
      setParameterBlacklist(newBlacklist.data);
    }
  }, [currentSubSystem]);

  const handleParameterBlacklistCheck = async (parameterTypeId) => {
    const blacklistItem = parameterBlacklist.filter(
      (item) => item.parameter_type_id === parameterTypeId
    );
    if (blacklistItem.length > 0) {
      const res = await deleteParameterBlacklist(blacklistItem[0].id);
      if (res.status === 200) {
        getBlacklist();
      }
    } else {
      const res = await postParameterBlacklist(
        parameterTypeId,
        currentProject.project_id,
        currentSubSystem.id
      );
      if (res.status === 201) {
        getBlacklist();
      }
    }
  };

  const getParametersAndBlacklist = useCallback(async () => {
    if (!hasExistingTemplate) setParameterGroupsLoading(true);
    await getParameterGroups(selectedTemplate);
    await getBlacklist();
    setParameterGroupsLoading(false);
  }, [selectedTemplate, getBlacklist, getParameterGroups, hasExistingTemplate]);

  useEffect(() => {
    setCurrentParameterGroups();
    if (selectedTemplate) {
      getParametersAndBlacklist();
    }
  }, [selectedTemplate, setCurrentParameterGroups, getParametersAndBlacklist]);

  useEffect(() => {
    getAllTemplates();
  }, [getAllTemplates]);

  return {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    setTemplate,
    toggleGroupOpen,
    clickParameterCell,
    selectedCell,
    getGroupHeaderStatus,
    checkApproveParameterGroup,
    addParameterOpen,
    onClose,
    clickEmptyCell,
    parameterBlacklist,
    handleParameterBlacklistCheck,
    parameterGroupsLoading,
    usersForThisSubsystem,
  };
};

export default useGrouping;
