import selectIcon from "./selectIcon";
import { format, isToday, formatDistanceToNow } from "date-fns";

function displayParameterStatusIcon(parameter) {
  return selectIcon(parameter.revision?.status);
}

function displayParameterName(parameter) {
  return parameter.parameter_type.name;
}

function displayParameterValue(parameter) {
  let parameterValue;
  if (parameter.revision) {
    parameterValue = parameter.revision.values[0].value;
    if (parameter.revision.values[0].unit) {
      parameterValue += ` ${parameter.revision.values[0].unit.name}`;
    }
  } else {
    parameterValue = "undefined";
  }
  return String(parameterValue);
}

function displayParameterRawValue(parameter) {
  if (parameter.revision) {
    const value = parameter.revision.values[0].value;
    if (value === "true" || value === "false") {
      return Boolean(parameter.revision.values[0].value);
    } else {
      return parameter.revision.values[0].value;
    }
  } else {
    return "undefined";
  }
}

function displayParameterUnit(parameter) {
  if (parameter.revision) {
    if (parameter.revision.values[0].unit) {
      return `${parameter.revision.values[0].unit.name}`;
    }
  } else {
    return null;
  }
}

function displayParameterParentAsset(parameter) {
  if (parameter.parents[0]) {
    return parameter.parents[0].name;
  } else {
    return "N/A";
  }
}

function displayParameterModificationInfo(parameter) {
  const formatModificationDate = (date) => {
    if (isToday(date)) {
      return formatDistanceToNow(date) + " ago";
    } else {
      return date.toDateString();
    }
  };

  if (parameter.revision) {
    return (
      parameter.revision.created_by.staff_name +
      ", " +
      formatModificationDate(new Date(parameter.revision.created_at))
    );
  } else {
    return "-";
  }
}

function displayParameterVersionHeader(revision) {
  const name = revision.created_by.staff_name;
  const date = new Date(revision.created_at);
  const formattedDate = format(date, "dd/MM/yyyy");
  return "Updated by " + name + " on " + formattedDate;
}

function displayParameterStatusHeader(records) {
  const status = records[0].type.name;
  const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  const staffName = records[0].created_by.staff_name;
  const statusDate = new Date(records[0].created_at);
  const formattedDate = format(statusDate, "dd/MM/yyyy");
  return `${formattedStatus} by ${staffName} on ${formattedDate}`;
}

export {
  displayParameterStatusIcon,
  displayParameterName,
  displayParameterValue,
  displayParameterRawValue,
  displayParameterUnit,
  displayParameterParentAsset,
  displayParameterModificationInfo,
  displayParameterVersionHeader,
  displayParameterStatusHeader,
};
