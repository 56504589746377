// Library imports
import { useSelector } from "react-redux";
import { Box, List } from "@mui/material";

// Components
import ParameterItem from "./parameterItem/parameterItem";
import SkeletonParameterItem from "./skeletonParameterItem/skeletonParameterItem";
import PanelHeader from "../panelHeader/panelHeader";
import EmptyState from "../emptyState/emptyState";

// Hooks
import useGetSystems from "../../hooks/useGetSystems";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../contexts/detailsContext";

function ParameterPanel() {
  const detailsContext = useContext(DetailsContext);
  const setCurrentParameter = detailsContext.setCurrentParameter;

  const { getCurrentSubSystem, getCurrentSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const currentSystem = getCurrentSystem();

  const parameters = useSelector(
    (state) => state.currentProjectParameters.currentProjectParameters
  );

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <PanelHeader
        currentSystem={currentSystem}
        currentSubSystem={currentSubSystem}
      />
      {!parameters || parameters?.length > 0 ? (
        <Box sx={{ flex: 1, overflowY: "scroll" }}>
          <List disablePadding>
            {parameters
              ? parameters.map((parameter) => (
                  <ParameterItem
                    parameter={parameter}
                    key={parameter.id}
                    setCurrentParameter={setCurrentParameter}
                  />
                ))
              : Array(10)
                  .fill(0)
                  .map((skeleton, i) => <SkeletonParameterItem key={i} />)}
          </List>
        </Box>
      ) : (
        <EmptyState
          title="No parameters found"
          description="Please add parameters"
        />
      )}
    </Box>
  );
}

export default ParameterPanel;
