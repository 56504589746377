// React
import { useContext, useEffect, useState } from "react";

// API
import { getParameterTypes, getUnits } from "../../api/ddb/parameterService";

// Context
import { DetailsContext } from "../../contexts/detailsContext";

// Redux
import { useSelector } from "react-redux";

//Hooks
import useGetSystems from "../../hooks/useGetSystems";

const useAddParameter = (open) => {
  const [loading, setLoading] = useState(false);
  const [newParameterSystem, setNewParameterSystem] = useState("Empty");
  const [newParameterSubSystem, setNewParameterSubSystem] = useState("Empty");
  const [newParameterAsset, setNewParameterAsset] = useState("Empty");
  const [newParameterTypeOptions, setNewParameterTypeOptions] =
    useState("Empty");
  const [newParameterType, setNewParameterType] = useState("Empty");
  const [newParameterValue, setNewParameterValue] = useState("");
  const [newParameterUnitOptions, setNewParameterUnitOptions] =
    useState("Empty");
  const [newParameterUnit, setNewParameterUnit] = useState("Empty");

  const { getCurrentSubSystem, getCurrentSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const currentSystem = getCurrentSystem();

  const detailsContext = useContext(DetailsContext);
  const currentAsset = detailsContext.currentAsset;
  const currentParameterType = detailsContext.currentParameterType;

  const systems = useSelector((state) => state.systems.systems);

  useEffect(() => {
    if (systems && currentSystem && open) {
      systems.forEach((system) => {
        if (system.id === currentSystem.id) {
          setNewParameterSystem(system);
        }
      });
    } else {
      setNewParameterSystem("Empty");
    }
  }, [systems, currentSystem, open]);

  useEffect(() => {
    if (newParameterSystem !== "Empty" && currentSubSystem && open) {
      newParameterSystem.subSystems.forEach((subSystem) => {
        if (subSystem.id === currentSubSystem.id) {
          setNewParameterSubSystem(subSystem);
        }
      });
    } else {
      setNewParameterSubSystem("Empty");
    }
  }, [newParameterSystem, currentSubSystem, open]);

  useEffect(() => {
    if (newParameterSubSystem !== "Empty" && currentAsset && open) {
      newParameterSubSystem.assets.forEach((asset) => {
        if (asset.id === currentAsset.id) {
          setNewParameterAsset(asset);
        }
      });
    } else {
      setNewParameterAsset("Empty");
    }
  }, [newParameterSubSystem, currentAsset, open]);

  useEffect(() => {
    const getNewParameterTypes = async () => {
      const parameterTypes = await getParameterTypes(
        newParameterAsset.asset_type.id
      );
      setNewParameterTypeOptions(parameterTypes);
    };
    if (newParameterAsset !== "Empty" && open) {
      getNewParameterTypes();
    } else {
      setNewParameterTypeOptions("Empty");
      setNewParameterType("Empty");
    }
  }, [newParameterAsset, open]);

  useEffect(() => {
    if (currentParameterType && newParameterTypeOptions !== "Empty" && open) {
      newParameterTypeOptions.parameter_types.forEach((parameter_type) => {
        if (parameter_type.id === currentParameterType.parameter_type_id) {
          setNewParameterType(parameter_type);
        }
      });
    }
  }, [currentParameterType, newParameterTypeOptions, open]);

  useEffect(() => {
    const getNewParameterUnits = async () => {
      const parameterUnits = await getUnits(newParameterType.unit_type.id);
      setNewParameterUnitOptions(parameterUnits);
    };
    if (newParameterType.unit_type && open) {
      getNewParameterUnits();
    } else {
      setNewParameterUnitOptions("Empty");
      setNewParameterUnit("Empty");
    }
  }, [newParameterType, open]);

  useEffect(() => {
    setNewParameterValue("");
  }, [open]);

  return {
    loading,
    setLoading,
    newParameterSystem,
    setNewParameterSystem,
    newParameterSubSystem,
    setNewParameterSubSystem,
    newParameterAsset,
    setNewParameterAsset,
    newParameterType,
    setNewParameterType,
    newParameterTypeOptions,
    newParameterValue,
    setNewParameterValue,
    newParameterUnit,
    setNewParameterUnit,
    newParameterUnitOptions,
  };
};

export default useAddParameter;
