import { useContext, useEffect } from "react";

// Contexts
import { SocketContext } from "../contexts/socketContext";
import { DetailsContext } from "../contexts/detailsContext";

// Redux
import { useSelector } from "react-redux";

function useSockets(currentProject) {
  const socketContext = useContext(SocketContext);
  const detailsContext = useContext(DetailsContext);
  const setActiveSocketUsers = detailsContext.setActiveSocketUsers;
  const setShowSocketUsers = detailsContext.setShowSocketUsers;
  const currentSubSystemId = detailsContext.currentSubSystemId;

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const setSocketActions = () => {
      if (currentProject?.project_id) {
        socketContext.socket.emit("msgToServer", {
          projectId: currentProject.project_id,
          currentSubSystemId: currentSubSystemId || null,
          user,
        });
      }
    };

    setSocketActions();
  }, [socketContext, currentProject, user, currentSubSystemId]);

  socketContext.socket.off("activeUserList").on("activeUserList", (list) => {
    if (user) {
      const usersInThisProject = list.filter((item) => {
        return (
          item.projectId === currentProject?.project_id &&
          item.user.email !== user.email
        );
        // return item.projectId === currentProject?.project_id;
      });
      setShowSocketUsers(usersInThisProject.length);
      setActiveSocketUsers(usersInThisProject);
    }
  });
}

export default useSockets;
