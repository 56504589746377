import axios from "axios";
import { BASE_URL, getHeaders } from "./index";

export const getTemplates = async () => {
  const templates = axios.get(`${BASE_URL}/templates`, {
    headers: await getHeaders(),
  });
  return templates;
};

export const getTemplate = async (assetId, projectId) => {
  const template = axios.get(
    `${BASE_URL}/template-logs/${assetId}/${projectId}`,
    { headers: await getHeaders() }
  );
  return template;
};

export const patchTemplate = async (assetId, projectId, templateId) => {
  const res = axios.patch(
    `${BASE_URL}/template-logs/${assetId}/${projectId}`,
    { template_id: templateId },
    { headers: await getHeaders() }
  );
  return res;
};

export const postTemplate = async (assetId, projectId, templateId) => {
  const res = axios.post(
    `${BASE_URL}/template-logs/`,
    {
      project_id: projectId,
      asset_id: assetId,
      template_id: templateId,
    },
    { headers: await getHeaders() }
  );
  return res;
};

export const getTemplateById = async (templateId) => {
  const template = await axios.get(`${BASE_URL}/templates/${templateId}`, {
    headers: await getHeaders(),
  });
  return template;
};
