// Material UI
import {
  CircleOutlined,
  PendingOutlined,
  DangerousOutlined,
  CheckCircleOutlined,
  ErrorOutline,
} from "@mui/icons-material";

function selectIcon(status, size) {
  if (!size) {
    size = "large";
  }
  switch (status) {
    case "answered":
      return <ErrorOutline fontSize={size} color="error" />;
    case "checked":
      return <PendingOutlined fontSize={size} color="warning" />;
    case "approved":
      return <CheckCircleOutlined fontSize={size} color="success" />;
    case "rejected":
      return <DangerousOutlined fontSize={size} color="error" />;
    default:
      return <CircleOutlined fontSize={size} color="secondary" />;
  }
}

export default selectIcon;
