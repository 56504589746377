// React
import { useCallback } from "react";
import { useEffect } from "react";

// Router
import { useLocation } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCurrentProject } from "../redux-store/projectSlices";

// API
import { getProjectById } from "../api/ddb/environmentContextService";

// Hooks
import useParameters from "./useParameters";
import useSystems from "./useSystems";

function useCurrentProject() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getAllParameters } = useParameters();
  const { assembleSystems } = useSystems();
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );
  const projectId = location.pathname.split("/")[2];

  const getCurrentProject = useCallback(async () => {
    const project = await getProjectById(projectId);
    dispatch(setCurrentProject(project));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!currentProject) {
      getCurrentProject();
    }
    if (currentProject) {
      getAllParameters();
      assembleSystems();
    }
  }, [currentProject, getCurrentProject, assembleSystems, getAllParameters]);

  return {
    currentProject,
  };
}

// Function to allow linking directly to a system/asset/parameter
// URL Example: projects/:project_id/details/systems/:system_id/assets/:asset_id/parameters/:parameter_id
// URL Example: projects/:project_id/details/parameters/:parameter_id
// URL Example: projects/:project_id/details/
// UseEffect
//take url
//look for "system", "asset", "parameter"
//if they exist
//look for id after these
//for each one set Current system/asset/parameter based on id
//look through these data structures
//Match id
//returne whole object
//set object as current

export default useCurrentProject;
