// Context
import { useContext } from "react";
import { DetailsContext } from "../../../contexts/detailsContext";

function Notes() {
  const detailsContext = useContext(DetailsContext);
  const notes = detailsContext.notes;

  return (
    <>
      <h1
        style={{
          color: "#E61E28",
          margin: "25px 0px 0px 0px",
          padding: "20px 0px 20px 0px",
        }}
      >
        Notes
      </h1>
      {notes && (
        <table
          cellSpacing={"0px"}
          cellPadding={"5px"}
          style={{
            borderCollapse: "collapse",
            border: "1px solid black",
            width: "100%",
          }}
        >
          <tbody>
            {notes?.map((note) => (
              <tr style={{ border: "1px solid black" }} key={note.id}>
                <th style={{ textAlign: "left", border: "1px solid black" }}>
                  {note.title}
                </th>
                <td style={{ border: "1px solid black" }}>
                  {note.description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default Notes;
