// React
import { useState } from "react";

// Redux
import { useSelector } from "react-redux";

// MUI
import { Badge, Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Login from "@mui/icons-material/Login";
import { useMsal } from "@azure/msal-react";

// Utils
import stringAvatar from "../../../utils/stringAvatar";

function UserAvatar() {
  const user = useSelector((state) => state.user.user);

  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const userLogin = () => {
    instance.loginRedirect().catch((e) => {
      console.error(e);
    });
  };

  return (
    <>
      <Tooltip title="User Menu">
        <Badge
          color={user ? "success" : "error"}
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiBadge-badge": {
              boxShadow: `0 0 0 2px white`,
            },
            cursor: "pointer",
          }}
          onClick={handleClick}
          data-cy="userAvatar"
        >
          {user ? (
            <Avatar {...stringAvatar(user.userName)} src={user.avatar} />
          ) : (
            <Avatar src={null} />
          )}
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-cy="userMenu"
        sx={{ zIndex: 2000, transform: "translateY(0.2rem)" }}
        autoFocus={false}
      >
        <MenuItem
          onClick={user ? userLogout : userLogin}
          data-cy="userMenuItem"
        >
          <ListItemIcon>{user ? <Logout /> : <Login />}</ListItemIcon>
          {user ? "Log out" : "Log in"}
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserAvatar;
