// Material UI Components
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Context
import { ApiErrorContext } from "../../contexts/apiErrorContext";

// React
import { useContext, forwardRef } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApiError = () => {
  const apiErrorsContext = useContext(ApiErrorContext);
  const open = apiErrorsContext.open;
  const setOpen = apiErrorsContext.setOpen;
  const severity = apiErrorsContext.severity;
  const message = apiErrorsContext.message;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ApiError;
