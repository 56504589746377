// Redux
import { useSelector, useDispatch } from "react-redux";
import { setBuildings } from "../redux-store/projectSlices";

// React
import { useCallback } from "react";

// API
import { BUILDING_TYPE_ID } from "../api/ddb";
import { getAssetsByType } from "../api/ddb/parameterService";

const useBuildings = () => {
  const dispatch = useDispatch();
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );
  const buildings = useSelector((state) => state.buildings.buildings);

  // this gets called in a useEffect in details route
  // in order to make sure that the buildings get refreshed if
  // a new project is chosen.
  const getBuildings = useCallback(async () => {
    const newBuildings = await getAssetsByType(
      BUILDING_TYPE_ID,
      currentProject.project_id
    );
    dispatch(setBuildings(newBuildings.assets));
  }, [dispatch, currentProject]);

  return {
    buildings,
    getBuildings,
  };
};

export default useBuildings;
