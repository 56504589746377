import PropTypes from "prop-types";

// Project Components
import PanelHeader from "../panelHeader/panelHeader";
import AddParameter from "../addParameter/addParameter";
import GroupingPanelNotes from "./groupingPanelNotes/groupingPanelNotes";
import GroupingSkeleton from "./groupingSkeleton";

// Material UI Components
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Checkbox,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  PendingOutlined,
  CheckCircleOutline,
  Save,
  Print,
} from "@mui/icons-material";

// Hooks
import useGrouping from "./use-hooks";
import useGetSystems from "../../hooks/useGetSystems";

// Utils
import selectIcon from "../../utils/selectIcon";
import stringArray from "../../utils/stringAvatar";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../contexts/detailsContext";

function GroupingPanel() {
  const detailsContext = useContext(DetailsContext);

  const { getCurrentSubSystem } = useGetSystems();

  const currentSubSystem = getCurrentSubSystem();

  const currentParameterGroups = detailsContext.currentParameterGroups;
  const setShowPrintPreview = detailsContext.setShowPrintPreview;
  const currentOpenGroup = detailsContext.currentOpenGroup;

  const {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    setTemplate,
    toggleGroupOpen,
    clickParameterCell,
    selectedCell,
    getGroupHeaderStatus,
    checkApproveParameterGroup,
    addParameterOpen,
    onClose,
    clickEmptyCell,
    parameterBlacklist,
    handleParameterBlacklistCheck,
    parameterGroupsLoading,
    usersForThisSubsystem,
  } = useGrouping();

  const amIOpen = (groupId) => {
    if (!currentOpenGroup) return false;
    return currentOpenGroup === groupId;
  };

  const CommonCell = (props) => {
    const { parameter, asset, parameterType } = props;
    return (
      <TableCell
        key={parameter.id}
        onClick={
          !("name" in parameter)
            ? () => clickParameterCell(parameter)
            : () => clickEmptyCell(parameterType, asset)
        }
        sx={{ py: 0, cursor: "pointer" }}
      >
        {"name" in parameter ? (
          <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
            Add
          </Typography>
        ) : (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography
                sx={
                  selectedCell?.id === parameter.id
                    ? { color: "primary.main", fontWeight: "bold" }
                    : null
                }
              >
                {typeof parameter.revision.values[0].value === "boolean"
                  ? parameter.revision.values[0].value === true
                    ? "Yes"
                    : "No"
                  : parameter.revision.values[0].value}{" "}
                {parameter.revision.values[0].unit
                  ? parameter.revision.values[0].unit.name
                  : null}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ pt: 1, pl: 1 }}>
                <Tooltip title={parameter.revision?.status}>
                  {selectIcon(parameter.revision?.status, "small")}
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        )}
      </TableCell>
    );
  };

  CommonCell.propTypes = {
    parameter: PropTypes.object,
    asset: PropTypes.object,
    parameterType: PropTypes.object,
  };

  const GroupProgress = (props) => {
    const { group1 } = props;
    const status = getGroupHeaderStatus(group1);
    return (
      <>
        <Box sx={{ pr: 1 }}>
          {group1.massLoading === true ? (
            <CircularProgress
              size={20}
              sx={{ color: "primary.main", mr: 1.5 }}
            />
          ) : (
            <>
              {status[2].count > 0 ? (
                <Tooltip title="Check all unchecked">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      checkApproveParameterGroup(
                        status[2].status,
                        "checked",
                        group1
                      );
                    }}
                  >
                    <PendingOutlined sx={{ color: "primary.dark" }} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {status[2].count === 0 && status[3].count > 0 ? (
                <Tooltip title="Approve all unapproved">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      checkApproveParameterGroup(
                        status[3].status,
                        "approved",
                        group1
                      );
                    }}
                  >
                    <CheckCircleOutline sx={{ color: "primary.dark" }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          )}
        </Box>
        <Box width="25%" sx={{ height: "15px", display: "inline-flex", pr: 1 }}>
          {status.map((status) => {
            if (status.count > 0) {
              return (
                <Tooltip
                  arrow={true}
                  placement="top"
                  key={Math.random().toString(36).substring(2, 17)}
                  title={status.percentage + "% " + status.status}
                >
                  <Box
                    width={status.percentage.toString() + "%"}
                    sx={{
                      backgroundColor: status.color,
                      color: status.color,
                      height: "15px",
                    }}
                  />
                </Tooltip>
              );
            } else {
              return null;
            }
          })}
        </Box>
      </>
    );
  };

  GroupProgress.propTypes = {
    group1: PropTypes.object,
  };

  return (
    <>
      <Box
        width="100%"
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <PanelHeader currentSubSystem={currentSubSystem} />
        <Box id="content" sx={{ flex: 1, overflowY: "auto", paddingTop: 2 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            id="templateSelection"
            sx={{ pl: 2 }}
          >
            <Grid item>
              <Typography>Select Template: </Typography>
            </Grid>
            <Grid item>
              <Select
                sx={{ width: 250 }}
                autoFocus
                size="small"
                value={selectedTemplate ? selectedTemplate : "Empty"}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                renderValue={() =>
                  selectedTemplate ? selectedTemplate.title : <em>Template</em>
                }
              >
                <MenuItem disabled value="Empty">
                  <em>Template</em>
                </MenuItem>
                {templates?.map((template) => (
                  <MenuItem value={template} key={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Tooltip title="Save template">
                <span>
                  <IconButton
                    onClick={() => {
                      setTemplate();
                    }}
                    disabled={!selectedTemplate}
                  >
                    <Save
                      sx={selectedTemplate ? { color: "primary.dark" } : null}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Print EDS">
                <span>
                  <IconButton
                    onClick={() => setShowPrintPreview(true)}
                    data-cy="print-page"
                    disabled={!currentParameterGroups}
                  >
                    <Print
                      sx={
                        currentParameterGroups
                          ? { color: "primary.dark" }
                          : null
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {usersForThisSubsystem && (
              <Grid item>
                <AvatarGroup>
                  {usersForThisSubsystem.map((item) => (
                    <Tooltip
                      key={item.clientId}
                      title={`${item.user.userName} is editing this item`}
                    >
                      <Avatar
                        {...stringArray(item.user.userName, {
                          width: 24,
                          height: 24,
                          fontSize: ".8em",
                        })}
                        src={item.user.avatar}
                      ></Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Grid>
            )}
          </Grid>
          {!parameterGroupsLoading ? (
            <>
              <Box id="grouping">
                <List>
                  {currentParameterGroups?.map((group1) => (
                    <Box key={group1.id}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => toggleGroupOpen(group1.id)}
                        >
                          <ListItemText primary={group1.title} />
                          {group1.sub_groups.map((subGroup) => (
                            <Chip
                              key={subGroup.id}
                              label={subGroup.title}
                              sx={{ mr: 1 }}
                            />
                          ))}
                          <GroupProgress group1={group1} />
                          {amIOpen(group1.id) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={amIOpen(group1.id)} sx={{ pl: 2 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                <Tooltip title="This indicates whether the row will be shown on the EDS or not (checked = shown).">
                                  <span>Printing</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell>Parameter Name</TableCell>
                              {currentSubSystem.assets.map((type) => (
                                <TableCell key={type.id}>{type.name}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {group1.eds_parameter_types.map((types) => (
                              <TableRow key={types.parameter_type_id}>
                                <TableCell sx={{ px: 0, py: 0 }} align="center">
                                  <Checkbox
                                    checked={
                                      parameterBlacklist.some(
                                        (item) =>
                                          item.parameter_type_id ===
                                          types.parameter_type_id
                                      )
                                        ? false
                                        : true
                                    }
                                    onChange={() =>
                                      handleParameterBlacklistCheck(
                                        types.parameter_type_id
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>{types.title}</TableCell>
                                {/* this relies on the asset and parameter orders
                            being guaranteed - ideally would be done differently */}
                                {types.parameters.map((parameter, index) => (
                                  <CommonCell
                                    parameter={parameter}
                                    key={parameter.id}
                                    asset={currentSubSystem.assets[index]}
                                    parameterType={types}
                                  />
                                ))}
                              </TableRow>
                            ))}
                            {group1.sub_groups.map((subGroup) => (
                              <>
                                <TableRow key={subGroup.id}>
                                  <TableCell colSpan="100%">
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {subGroup.title}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {subGroup.eds_parameter_types.map(
                                  (types, index) => (
                                    <TableRow
                                      key={types.parameter_type_id}
                                      sx={
                                        index === 0
                                          ? {
                                              borderTop: 1,
                                              borderColor: "common.grey",
                                            }
                                          : null
                                      }
                                    >
                                      <TableCell
                                        sx={{ px: 0, py: 0 }}
                                        align="center"
                                      >
                                        <Checkbox
                                          checked={
                                            parameterBlacklist.some(
                                              (item) =>
                                                item.parameter_type_id ===
                                                types.parameter_type_id
                                            )
                                              ? false
                                              : true
                                          }
                                          onChange={() =>
                                            handleParameterBlacklistCheck(
                                              types.parameter_type_id
                                            )
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>{types.title}</TableCell>
                                      {types.parameters.map(
                                        (parameter, index) => (
                                          <CommonCell
                                            parameter={parameter}
                                            key={parameter.id}
                                            asset={
                                              currentSubSystem.assets[index]
                                            }
                                            parameterType={types}
                                          />
                                        )
                                      )}
                                    </TableRow>
                                  )
                                )}
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </Box>
                  ))}
                </List>
              </Box>
              <GroupingPanelNotes />
            </>
          ) : (
            <GroupingSkeleton />
          )}
        </Box>
      </Box>
      <AddParameter open={addParameterOpen} onClose={onClose} />
    </>
  );
}

export default GroupingPanel;
