// Context
import { DetailsContext } from "../contexts/detailsContext";

// API
import { getNotesByAssetId } from "../api/eds/edsNotes";
import { getQaStatusByAssetId } from "../api/eds/edsQa";

// React
import { useContext, useCallback } from "react";

//Hooks
import useGetSystems from "../hooks/useGetSystems";

const useCurrentNotes = () => {
  const detailsContext = useContext(DetailsContext);
  const setNotes = detailsContext.setNotes;

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const getNotes = useCallback(async () => {
    if (currentSubSystem) {
      const newNotes = await getNotesByAssetId(currentSubSystem.id);
      if (newNotes.length > 0) {
        const qaStatus = await getQaStatusByAssetId(currentSubSystem.id);
        const assembledNotes = newNotes.map((note) => {
          let qaData = [];
          qaStatus.forEach((qa) => {
            if (qa.note_id === note.id) {
              qaData.push(qa);
            }
          });
          return { ...note, qaData };
        });
        setNotes(assembledNotes);
        return assembledNotes;
      }
    }
  }, [currentSubSystem, setNotes]);

  return { getNotes };
};

export default useCurrentNotes;
