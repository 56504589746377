// MUI
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// Project Components
import Navigation from "../../components/navigation/navigation";
import DetailsPanel from "../../components/detailsPanel/detailsPanel";
import MiddlePanel from "./middlePanel";

// Hooks
import useCurrentProject from "../../hooks/useCurrentProject";
import useBuildings from "../../hooks/useBuildings";

// Redux
import { useDispatch } from "react-redux";
import { setSystems } from "../../redux-store/projectSlices";

// React
import { useEffect } from "react";

export default function Details() {
  const { currentProject } = useCurrentProject();

  const dispatch = useDispatch();
  const { getBuildings } = useBuildings();
  useEffect(() => {
    if (currentProject) {
      dispatch(setSystems());
      getBuildings();
    }
  }, [currentProject, getBuildings, dispatch]);

  return (
    <>
      {currentProject ? (
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          <CssBaseline />
          <Navigation />
          <MiddlePanel />
          <DetailsPanel />
        </Box>
      ) : null}
    </>
  );
}
