import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const ApiErrorContext = createContext();

function ApiErrorProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setSeverity("error");
    setMessage(errorMessage);
    setOpen(true);
  }, [errorMessage]);

  const value = {
    open,
    severity,
    message,
    setOpen,
    setErrorMessage,
  };

  return (
    <ApiErrorContext.Provider value={value}>
      {children}
    </ApiErrorContext.Provider>
  );
}

ApiErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ApiErrorContext, ApiErrorProvider };
