// EDS URL for API calls

import { getEdsApiToken } from "../../auth/auth";

export const BASE_URL =
  "https://arup-bm-eds-api-test-backend04-api.azurewebsites.net";

export const getHeaders = async () => {
  const token = await getEdsApiToken();
  return {
    "Content-Type": "application/json",
    Authorization: token,
  };
};
