// Context
import { DetailsContext } from "../contexts/detailsContext";

// Redux
import { useSelector } from "react-redux";

// React
import { useContext } from "react";

const useGetSystems = () => {
  const detailsContext = useContext(DetailsContext);
  const currentSystemId = detailsContext.currentSystemId;
  const currentSubSystemId = detailsContext.currentSubSystemId;
  const systems = useSelector((state) => state.systems.systems);

  const getSystem = (systemId) => {
    if (systems) return systems.find((system) => system.id === systemId);
    return null;
  };

  const getSubSystem = (systemId, subSystemId) => {
    const system = getSystem(systemId);
    if (system)
      return system.subSystems?.find(
        (subsystem) => subsystem.id === subSystemId
      );
    return null;
  };

  const getCurrentSystem = () => {
    return getSystem(currentSystemId);
  };

  const getCurrentSubSystem = () => {
    return getSubSystem(currentSystemId, currentSubSystemId);
  };

  return {
    getCurrentSystem,
    getCurrentSubSystem,
  };
};

export default useGetSystems;
