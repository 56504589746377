// Material UI Components
import { Add, Check, Close } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Alert,
  Tooltip,
  CircularProgress,
} from "@mui/material";

// Redux
import { useSelector } from "react-redux";

// React
import { useRef, useState } from "react";

// Prop types
import PropTypes from "prop-types";

export default function AddSystem(props) {
  const {
    addingSystem,
    setAddingSystem,
    newSystem,
    setNewSystem,
    addAsset,
    systems,
    openAddSystem,
    newBuilding,
    setNewBuilding,
  } = props;

  const buildings = useSelector((state) => state.buildings.buildings);

  const bottomElementRef = useRef();

  const scrollToBottom = () => {
    bottomElementRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const [loading, setLoading] = useState(false);

  return (
    <ListItem
      data-cy="nav-new-system"
      sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
    >
      {!addingSystem ? (
        <ListItemButton
          onClick={async () => {
            await openAddSystem();
            scrollToBottom();
          }}
        >
          <ListItemText
            primary="Add new system"
            sx={{ color: "primary.dark" }}
          />
          <ListItemIcon sx={{ minWidth: 24 }}>
            <Add sx={{ color: "primary.dark" }} />
          </ListItemIcon>
        </ListItemButton>
      ) : (
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ pl: 2, pt: "4px", pb: "4px" }}
          spacing={1}
        >
          <Grid item xs={10}>
            <TextField
              autoFocus
              data-cy="nav-new-system-text"
              variant="outlined"
              size="small"
              value={newSystem}
              label="System"
              onChange={(e) => {
                setNewSystem(e.target.value);
              }}
              sx={{ width: "100%", mt: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* wrapper needed for disabled button in Tooltip */}
                    {!loading ? (
                      <Tooltip title="Add system">
                        <span>
                          <IconButton
                            data-cy="nav-system-add-button"
                            disabled={!newSystem || !newBuilding}
                            onClick={async () => {
                              setLoading(true);
                              await addAsset(systems, "system");
                              setLoading(false);
                            }}
                          >
                            <Check
                              sx={
                                newSystem && newBuilding
                                  ? { color: "primary.dark" }
                                  : { color: "none" }
                              }
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Cancel">
              <IconButton onClick={() => setAddingSystem(false)} sx={{ mt: 1 }}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            {buildings.length > 0 ? (
              <Select
                data-cy="nav-new-system-building"
                sx={{ width: "100%", mt: 1 }}
                size="small"
                label="Building"
                value={newBuilding}
                onChange={(e) => setNewBuilding(e.target.value)}
                ref={bottomElementRef}
              >
                {buildings.map((building) => (
                  <MenuItem
                    data-cy="nav-new-system-building-dropdown"
                    value={building}
                    key={building.id}
                  >
                    {building.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Alert severity="error">
                No Buildings in this project, please assign one in DDB (
                <a
                  href={`https://ddb-sandbox.arup.com/projects/${currentProject.project_id}/parameters`}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                ) and reload.
              </Alert>
            )}
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
}

AddSystem.propTypes = {
  addingSystem: PropTypes.bool,
  setAddingSystem: PropTypes.func,
  newSystem: PropTypes.string,
  setNewSystem: PropTypes.func,
  addAsset: PropTypes.func,
  systems: PropTypes.array,
  openAddSystem: PropTypes.func,
  // buildings: PropTypes.array,
  // newBuilding: PropTypes.string,
  newBuilding: PropTypes.object,
  setNewBuilding: PropTypes.func,
  scrollToBottom: PropTypes.func,
  bottomElementRef: PropTypes.any,
};
