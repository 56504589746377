import { createContext, useState } from "react";
import PropTypes from "prop-types";

const DetailsContext = createContext();

function DetailsProvider({ children }) {
  const [currentParameter, setCurrentParameter] = useState();

  const [currentSystemId, setCurrentSystemId] = useState();
  const [currentSubSystemId, setCurrentSubSystemId] = useState();

  const [currentAsset, setCurrentAsset] = useState();
  const [currentParameterType, setCurrentParameterType] = useState();
  const [currentNote, setCurrentNote] = useState();
  const [notes, setNotes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [currentParameterGroups, setCurrentParameterGroups] = useState();
  const [activeSocketUsers, setActiveSocketUsers] = useState();
  const [showSocketUsers, setShowSocketUsers] = useState(false);
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [currentOpenGroup, setCurrentOpenGroup] = useState(null);

  const value = {
    currentParameter,
    setCurrentParameter,
    currentSystemId,
    setCurrentSystemId,
    currentSubSystemId,
    setCurrentSubSystemId,
    currentAsset,
    setCurrentAsset,
    currentParameterType,
    setCurrentParameterType,
    currentNote,
    setCurrentNote,
    notes,
    setNotes,
    selectedTemplate,
    setSelectedTemplate,
    currentParameterGroups,
    setCurrentParameterGroups,
    activeSocketUsers,
    setActiveSocketUsers,
    showSocketUsers,
    setShowSocketUsers,
    showPrintPreview,
    setShowPrintPreview,
    currentOpenGroup,
    setCurrentOpenGroup,
  };

  return (
    <DetailsContext.Provider value={value}>{children}</DetailsContext.Provider>
  );
}

DetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DetailsContext, DetailsProvider };
