// Material UI Components
import { ExpandMore, ExpandLess, Edit } from "@mui/icons-material";
import {
  Divider,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

// Hooks
import useNavigationContent from "./use-hooks";
import useSystems from "../../../hooks/useSystems";

// Router
import { useLocation } from "react-router-dom";

// Project Components
import AddSystem from "./addSystem";
import AddSubSystem from "./addSubSystem";
import AddAsset from "./addAsset";
import EditAsset from "./editAsset";

// Redux
import { useSelector } from "react-redux";

// React
import { useState, useContext } from "react";

// Contexts
import { DetailsContext } from "../../../contexts/detailsContext";

function NavigationContent() {
  const { assembleSystems } = useSystems();
  const {
    toggleSystem,
    toggleSubSystem,
    addingAsset,
    setAddingAsset,
    addAsset,
    newAsset,
    setNewAsset,
    addingSubSystem,
    setAddingSubSystem,
    newSubSystem,
    setNewSubSystem,
    addingSystem,
    setAddingSystem,
    newSystem,
    setNewSystem,
    openAddSystem,
    newBuilding,
    setNewBuilding,
  } = useNavigationContent(assembleSystems);

  const location = useLocation();
  const systems = useSelector((state) => state.systems.systems);

  const [open, setOpen] = useState(false);
  const [editingAsset, setEditingAsset] = useState();

  const detailsContext = useContext(DetailsContext);
  const currentSubSystemId = detailsContext.currentSubSystemId;
  const currentSystemId = detailsContext.currentSystemId;

  const isSystemOpen = (id) => {
    if (!currentSystemId) return false;
    return currentSystemId === id;
  };

  const isSubSystemOpen = (id) => {
    if (!currentSubSystemId) return false;
    return currentSubSystemId === id;
  };

  return (
    <>
      {systems ? (
        <>
          <Divider />
          {systems.map((system) => (
            <div key={system.id}>
              <ListItem data-cy="nav-system-group" disablePadding>
                <ListItemButton
                  onClick={() => {
                    toggleSystem(system.id);
                  }}
                >
                  <ListItemText
                    primary={system.name}
                    sx={
                      system.open
                        ? { color: "primary.dark" }
                        : { color: "black" }
                    }
                  />
                  <Tooltip title="Edit system">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                        setEditingAsset(system);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Chip
                    key={system.id}
                    label={system.building}
                    sx={{ ml: 1, float: "right", cursor: "pointer" }}
                  />
                  {isSystemOpen(system.id) ? (
                    <ExpandLess sx={{ color: "primary.dark" }} />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Divider />
              <Collapse
                in={isSystemOpen(system.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {system.subSystems.map((subSystem) => (
                    <div key={subSystem.id}>
                      <ListItem
                        data-cy="nav-sub-system-group"
                        key={subSystem.id}
                        sx={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <ListItemButton
                          onClick={() => {
                            toggleSubSystem(subSystem.id);
                          }}
                        >
                          <ListItemText
                            primary={subSystem.name}
                            sx={
                              isSubSystemOpen(subSystem.id)
                                ? { color: "primary.dark" }
                                : { color: "black" }
                            }
                          />
                          <Tooltip title="Edit subsystem">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                                setEditingAsset(subSystem);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {isSubSystemOpen(subSystem.id) ? (
                            <ExpandLess sx={{ color: "primary.dark" }} />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={isSubSystemOpen(subSystem.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        {subSystem.assets.map((asset) => (
                          <ListItem
                            data-cy="nav-type-group"
                            key={asset.id}
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <ListItemText
                              primary={asset.name}
                              primaryTypographyProps={{
                                sx: {
                                  pl: 4,
                                  py: "0.5rem",
                                  overflowWrap: "break-word",
                                },
                              }}
                            />
                            <Tooltip title="Edit type">
                              <IconButton
                                sx={{ mr: 3 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(true);
                                  setEditingAsset(asset);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                        ))}
                        {location.pathname.includes("/details") ? (
                          <AddAsset
                            addingAsset={addingAsset}
                            setAddingAsset={setAddingAsset}
                            newAsset={newAsset}
                            setNewAsset={setNewAsset}
                            addAsset={addAsset}
                            subSystem={subSystem}
                          />
                        ) : null}
                      </Collapse>
                    </div>
                  ))}
                  {location.pathname.includes("/details") ? (
                    <AddSubSystem
                      addingSubSystem={addingSubSystem}
                      setAddingSubSystem={setAddingSubSystem}
                      newSubSystem={newSubSystem}
                      setNewSubSystem={setNewSubSystem}
                      addAsset={addAsset}
                      system={system}
                    />
                  ) : null}
                </List>
                <Divider />
              </Collapse>
            </div>
          ))}
          {location.pathname.includes("/details") ? (
            <>
              <AddSystem
                addingSystem={addingSystem}
                setAddingSystem={setAddingSystem}
                newSystem={newSystem}
                setNewSystem={setNewSystem}
                addAsset={addAsset}
                systems={systems}
                openAddSystem={openAddSystem}
                newBuilding={newBuilding}
                setNewBuilding={setNewBuilding}
              />
            </>
          ) : null}
          <EditAsset
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            editingAsset={editingAsset}
          />
        </>
      ) : (
        <>
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
        </>
      )}
    </>
  );
}

export default NavigationContent;
