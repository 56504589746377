import axios from "axios";

import { BASE_URL, getHeaders } from "./index";

// This retrieves the blacklisted parameters for an eds (should be passed the currentSubSytem id)
export const getParameterBlacklist = async (assetId) => {
  const parameterBlacklist = await axios.get(
    `${BASE_URL}/parameter-type-blacklist/${assetId}`,
    {
      headers: await getHeaders(),
    }
  );
  return parameterBlacklist;
};

// This sends a single item (a row on the table of a single eds) to the blacklist table
export const postParameterBlacklist = async (
  parameterTypeId,
  projectId,
  assetId
) => {
  const res = await axios.post(
    `${BASE_URL}/parameter-type-blacklist`,
    {
      parameter_type_id: parameterTypeId,
      project_id: projectId,
      asset_id: assetId,
    },
    { headers: await getHeaders() }
  );
  return res;
};

// Important: 'blacklistId' refers to the 'id' field of a single blacklist object
export const deleteParameterBlacklist = async (blacklistId) => {
  const res = await axios.delete(
    `${BASE_URL}/parameter-type-blacklist/${blacklistId}`,
    { headers: await getHeaders() }
  );
  return res;
};
