// React
import { useCallback } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setSystems } from "../redux-store/projectSlices";

// API
import {
  getSystems,
  getSubSystems,
  getAssetsByType,
} from "../api/ddb/parameterService";

function useSystems() {
  const dispatch = useDispatch();

  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const assembleSystems = useCallback(async () => {
    // Returns all systems
    const res = await getSystems(currentProject.project_id);
    const newSystems = res.assets;
    let subSystemTree = {};
    let systemTree = {};

    if (newSystems.length > 0) {
      const systemIds = newSystems.map((sys) => sys.id);
      const subSystems = await getSubSystems(systemIds);
      if (subSystems.assets.length > 0) {
        const subSystemIds = subSystems.assets.map((subSys) => subSys.id);
        const assets = await getSubSystems(subSystemIds);

        // Bottom-up approach

        // First assemble sub-systems and assets
        subSystemTree = subSystems.assets.map((subSys) => {
          const newAssets = assets.assets.filter(
            (asset) => asset.parent === subSys.id
          );
          return { ...subSys, assets: newAssets };
        });
      } else {
        // If there are no assets returned then the tree is just the subSystems so we just map that into the systems object
        subSystemTree = subSystems.assets;
      }

      // Get all buildings
      const buildings = await getAssetsByType(
        "dbeac84d-9235-47fb-ae08-c8d47e00f253",
        currentProject.project_id
      );

      // Then add the assembled sub-systems into their system
      systemTree = newSystems.map((system) => {
        const newSubSystems = subSystemTree.filter(
          (subSys) => subSys.parent === system.id
        );
        let currentBuilding;
        if (buildings.assets.length > 0) {
          buildings.assets.forEach((building) => {
            if (building.children.includes(system.id)) {
              currentBuilding = building.name;
            }
          });
        }
        return {
          ...system,
          subSystems: newSubSystems,
          building: currentBuilding,
        };
      });
      dispatch(setSystems(systemTree));
    } else {
      dispatch(setSystems([]));
    }
  }, [currentProject, dispatch]);

  return {
    assembleSystems,
  };
}

export default useSystems;
