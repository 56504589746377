// React
import { useState } from "react";
import { useSelector } from "react-redux";

// API
import { postQaStatus } from "../../../../../api/eds/edsQa";

// Hooks
import useCurrentNotes from "../../../../../hooks/useCurrentNotes";
import useGetSystems from "../../../../../hooks/useGetSystems";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../../../../contexts/detailsContext";

const useRejectNoteModal = (setRejectModalOpen) => {
  const { getNotes } = useCurrentNotes();
  const detailsContext = useContext(DetailsContext);
  const setCurrentNote = detailsContext.setCurrentNote;
  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleReject = async (currentNoteId) => {
    setLoading(true);
    const currentId = currentNoteId;
    const res = await postQaStatus(
      currentNoteId,
      currentSubSystem.id,
      "rejected",
      user.userName,
      new Date(),
      comment
    );
    if (res.status === 201) {
      const newNotes = await getNotes();
      newNotes.forEach((note) => {
        if (note.id === currentId) {
          setCurrentNote(note);
        }
      });
      setLoading(false);
      setRejectModalOpen(false);
    }
  };

  return {
    comment,
    setComment,
    handleReject,
    loading,
  };
};

export default useRejectNoteModal;
