// Material UI Components
import {
  Button,
  Autocomplete,
  TextField,
  Container,
  Typography,
  Stack,
  Box,
  Link,
  Chip,
} from "@mui/material";

// Redux
import { useSelector } from "react-redux";

//Hooks
import useProjects from "./use-hooks";

//Utils
import projectDisplayName from "../../utils/projectDisplayName";

export default function Projects() {
  const { handleCurrentProject, goToProject, goToRecent } = useProjects();
  const projects = useSelector((state) => state.project.projects);
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        height: "100%",
        transform: "translateY(-7vh)",
        maxWidth: {
          xl: "md",
          lg: "md",
          md: "sm",
          sm: "sm",
          xs: "xs",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          options={projects ? projects : []}
          getOptionLabel={(project) => projectDisplayName(project)}
          renderOption={(props, project) => (
            <Box component="li" {...props}>
              <Typography>{projectDisplayName(project)}</Typography>
              {project.confidential ? (
                <Chip
                  label="Confidential"
                  variant="outlined"
                  size="small"
                  sx={{ borderColor: "error.main", fontWeight: "bold", ml: 1 }}
                />
              ) : null}
            </Box>
          )}
          onChange={(e, value) => handleCurrentProject(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Project"
              data-cy="projects-textfield"
            />
          )}
          data-cy="projects-dropdown"
        />
        <Button
          variant="outlined"
          sx={{ marginTop: "0.5rem", color: "primary.dark" }}
          onClick={() => goToProject()}
          data-cy="open-project"
        >
          Open Project
        </Button>
      </Box>
      <Stack spacing={1} sx={{ marginTop: "2.5rem", width: "100%" }}>
        <Typography variant="body1">Recently opened:</Typography>
        {JSON.parse(localStorage.getItem("recentProjects")).map((project) => (
          <Link
            variant="body2"
            underline="none"
            onClick={() => goToRecent(project)}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
              cursor: "pointer",
              color: "primary.dark",
            }}
            key={project.number}
            data-cy="project-link"
          >
            {projectDisplayName(project)}
            {project.confidential ? (
              <Chip
                label="Confidential"
                variant="outlined"
                size="small"
                sx={{ borderColor: "error.main", fontWeight: "bold", ml: 1 }}
              />
            ) : null}
          </Link>
        ))}
      </Stack>
    </Container>
  );
}
