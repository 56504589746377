// React
import { useState } from "react";

// API
import { rejectParameter } from "../../../../api/ddb/parameterService";

// Utils
import useReloadParameters from "../../useReloadParameters";

// Hooks
import useGetParameterGroups from "../../../../hooks/useGetParameterGroups";

const useRejectModal = (setRejectModalOpen) => {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { reloadParams } = useReloadParameters();
  const { getParameterGroups } = useGetParameterGroups();

  const handleReject = async (currentParameterId) => {
    setLoading(true);
    const res = await rejectParameter(currentParameterId, comment);
    if (res.status === 200) {
      reloadParams(currentParameterId);
      getParameterGroups();
      setComment("");
      setLoading(false);
      setRejectModalOpen(false);
    }
  };

  return {
    comment,
    setComment,
    handleReject,
    loading,
  };
};

export default useRejectModal;
