// React
import { useContext } from "react";

// Redux
import { useSelector } from "react-redux";

// Hooks
import useParameters from "../../hooks/useParameters";

// Context
import { DetailsContext } from "../../contexts/detailsContext";

const useReloadParameters = () => {
  const parameters = useSelector(
    (state) => state.currentProjectParameters.currentProjectParameters
  );
  const { getSomeParameters } = useParameters();
  const detailsContext = useContext(DetailsContext);
  const setCurrentParameter = detailsContext.setCurrentParameter;
  const reloadParams = async (parameterId) => {
    const parentIds = parameters.map((parameter) => {
      return parameter.parents[0].id;
    });
    const newParameters = await getSomeParameters(parentIds);
    newParameters.forEach((newParam) => {
      if (newParam.id === parameterId) {
        setCurrentParameter(newParam);
      }
    });
  };

  return {
    reloadParams,
  };
};

export default useReloadParameters;
