import PropTypes from "prop-types";

// Material UI Components
import {
  Drawer,
  Typography,
  Box,
  Toolbar,
  Stack,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// Utils
import selectIcon from "../../../utils/selectIcon";

function UserGuidance(props) {
  const { userGuideOpen, setUserGuideOpen } = props;

  const status = ["", "rejected", "answered", "checked", "approved"];

  return (
    <Drawer
      anchor="right"
      open={userGuideOpen}
      onClose={() => setUserGuideOpen(false)}
    >
      <Toolbar />
      <Box sx={{ pt: 1, px: 2, width: 400 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5">User Guidance</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={() => setUserGuideOpen(false)}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box id="legend" sx={{ pt: 2 }}>
          <Typography variant="h6">QA Status Legend</Typography>
          <Stack spacing={1}>
            {status.map((status) => (
              <Grid container direction="row" alignItems="center" key={status}>
                <Grid item>{selectIcon(status)}</Grid>
                <Grid item>
                  <Typography sx={{ pb: 1, pl: 1 }}>
                    {status ? status : "unanswered"}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Box>
        <Box id="documentation" sx={{ pt: 2 }}>
          <Typography variant="h6">Documentation</Typography>
          <Typography sx={{ pt: 1 }}>
            Documentation is an important part of the development process, find
            our developer diaries and more&nbsp;
            <a
              href="https://arup.sharepoint.com/:f:/s/MidlandsDigital/EpkT1PCmNoJGpafFI7Wsvp4BmHdQgHF80hBkVOpFUwU8UA?e=gXymdb"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Typography>
        </Box>
        <Box id="developer" sx={{ pt: 2 }}>
          <Typography variant="h6">Developer Links</Typography>
          <Stack spacing={1} sx={{ pt: 1 }}>
            <Typography>Links to relevant API documnetation below:</Typography>
            <Typography>
              Find DDB Sanbox Documentation&nbsp;
              <a
                href="https://ddb-sandbox.arup.com/documentation"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Typography>
            <Typography>
              Find live DDB Documentation&nbsp;
              <a
                href="https://ddb.arup.com/documentation"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Typography>
            <Typography>
              Find EDS API Documentation&nbsp;
              <a
                href="https://arup-bm-eds-api-test-backend04-api.azurewebsites.net/api"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Typography>
          </Stack>
        </Box>
        <Box id="helpVideo" sx={{ pt: 2 }}>
          <Typography variant="h6">Need more Guidance?</Typography>
          <Typography>
            Check out our help video below, which takes you through creating
            your first EDS:
          </Typography>
          <Box sx={{ pt: 1 }}>
            <iframe
              id="kmsembed-1_4eoqa5du"
              width="100%"
              height="250"
              src="https://media.arup.com/embed/secure/iframe/entryId/1_4eoqa5du/uiConfId/26567841/st/0"
              allowFullScreen
              frameBorder="0"
              title="EDS Demonstration"
            />
          </Box>
          <Typography>
            Please click&nbsp;
            <a
              href="https://media.arup.com/embed/secure/iframe/entryId/1_4eoqa5du/uiConfId/26567841/st/0"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            &nbsp;if you have any issues viewing the video.
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}

UserGuidance.propTypes = {
  userGuideOpen: PropTypes.bool,
  setUserGuideOpen: PropTypes.func,
};

export default UserGuidance;
