// Hooks
import useGroupingNotes from "./useGroupingNotes";

// Material UI Components
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  Button,
  IconButton,
  TextField,
  Divider,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Check,
  Close,
  PendingOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";

// Utils
import selectIcon from "../../../utils/selectIcon";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../../contexts/detailsContext";

function GroupingPanelNotes() {
  const {
    notesOpen,
    setNotesOpen,
    notes,
    editNotes,
    setEditNotes,
    addNote,
    newTitle,
    setNewTitle,
    newNote,
    setNewNote,
    getNoteHeaderStatus,
    handleSelectNote,
    massCheckApproveNotes,
  } = useGroupingNotes();

  const detailsContext = useContext(DetailsContext);
  const currentNote = detailsContext.currentNote;
  const setCurrentNote = detailsContext.setCurrentNote;

  const NoteHeader = () => {
    let status = getNoteHeaderStatus();

    return (
      <>
        <Box sx={{ pr: 1.5 }}>
          {status[2].count > 0 ? (
            <Tooltip title="Check all unchecked">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  massCheckApproveNotes("checked");
                }}
              >
                <PendingOutlined sx={{ color: "primary.dark" }} />
              </IconButton>
            </Tooltip>
          ) : null}
          {status[2].count === 0 && status[3].count > 0 ? (
            <Tooltip title="Approve all unapproved">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  massCheckApproveNotes("approved");
                }}
              >
                <CheckCircleOutline sx={{ color: "primary.dark" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
        <Box width="25%" sx={{ height: "15px", display: "inline-flex", pr: 1 }}>
          {status.map((status) => {
            if (status.count > 0) {
              return (
                <Tooltip
                  arrow={true}
                  placement="top"
                  key={status.percentage + status.status}
                  title={status.percentage + "% " + status.status}
                >
                  <Box
                    width={status.percentage.toString() + "%"}
                    sx={{
                      backgroundColor: status.color,
                      color: status.color,
                      height: "15px",
                    }}
                  />
                </Tooltip>
              );
            } else {
              return null;
            }
          })}
        </Box>
      </>
    );
  };

  return (
    <List>
      <Box>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            data-cy="group-panel-notes"
            onClick={() => {
              setNotesOpen(!notesOpen);
              setCurrentNote();
            }}
          >
            <ListItemText primary="Notes" />
            <NoteHeader />
            {notesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={notesOpen}>
          <Table>
            <TableBody>
              {notes.map((note) => (
                <TableRow
                  data-cy="group-panel-notes-table-fields"
                  key={note.id}
                  onClick={() => handleSelectNote(note)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    sx={
                      currentNote?.description === note.description &&
                      currentNote.title === note.title
                        ? {
                            color: "primary.dark",
                            fontWeight: "bold",
                          }
                        : null
                    }
                  >
                    {note.title}
                  </TableCell>
                  <TableCell
                    sx={
                      currentNote?.description === note.description &&
                      currentNote.title === note.title
                        ? {
                            color: "primary.dark",
                            fontWeight: "bold",
                          }
                        : null
                    }
                  >
                    {note.description}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <Tooltip title={note.qaData[0]?.status}>
                      {selectIcon(note.qaData[0]?.status)}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5}>
                  {editNotes ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="start"
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <TextField
                          data-cy="group-panel-add-notes-text-title"
                          autoFocus
                          label="Title"
                          value={newTitle}
                          onChange={(e) => setNewTitle(e.target.value)}
                          required
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          data-cy="group-panel-add-notes-text-notes"
                          multiline
                          rows={3}
                          label="Note"
                          value={newNote}
                          onChange={(e) => setNewNote(e.target.value)}
                          required
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          data-cy="group-panel-add-notes-add-button"
                          onClick={() => addNote()}
                          disabled={!newTitle && !newNote}
                        >
                          <Check
                            sx={{
                              color:
                                newTitle && newNote
                                  ? "primary.dark"
                                  : "secondary.light",
                            }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => setEditNotes(false)}>
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Button
                      data-cy="group-panel-add-notes"
                      onClick={() => setEditNotes(true)}
                    >
                      Add Note
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </Box>
    </List>
  );
}

export default GroupingPanelNotes;
