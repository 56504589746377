import PropTypes from "prop-types";

// React
import { useContext } from "react";

// Context
import { DetailsContext } from "../../../contexts/detailsContext";

// Project Components
import EmptyState from "../../emptyState/emptyState";
import EdsNoteContent from "./edsNoteContent/edsNoteContent";
import ParameterDetails from "./parameterDetails/parameterDetails";

function DetailsContent(props) {
  const detailsContext = useContext(DetailsContext);
  const currentParameter = detailsContext.currentParameter;
  const currentNote = detailsContext.currentNote;

  const { smallScreenBack, smallScreen } = props;

  if (currentParameter) {
    return (
      <ParameterDetails
        currentParameter={currentParameter}
        smallScreen={smallScreen}
        smallScreenBack={smallScreenBack}
      />
    );
  } else if (currentNote) {
    return (
      <EdsNoteContent
        smallScreen={smallScreen}
        smallScreenBack={smallScreenBack}
      />
    );
  } else {
    return (
      <EmptyState
        title="No parameter selected"
        description="Please select a parameter to view details"
      />
    );
  }
}

DetailsContent.propTypes = {
  smallScreenBack: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool.isRequired,
};

export default DetailsContent;
