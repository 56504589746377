import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import useHooks from "./hooks";

function HomeButton() {
  const navigate = useNavigate();
  const useIsWidthUp = useHooks();

  return (
    <Tooltip title="Home">
      <Button
        sx={{
          color: "primary.contrastText",
          textTransform: "none",
          paddingLeft: 1.5,
        }}
        size="large"
        onClick={() => navigate("/")}
        data-cy="edsButton"
      >
        <ArticleIcon fontSize="large" />
        {useIsWidthUp("sm") ? `Asset Data Management` : `ADM`}
      </Button>
    </Tooltip>
  );
}

export default HomeButton;
