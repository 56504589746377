// API
import { getSelectedParameters } from "../api/ddb/parameterService";
import { getTemplateById } from "../api/eds/templates";

// React
import { useContext, useCallback } from "react";

// Redux
import { useSelector } from "react-redux";

// Context
import { DetailsContext } from "../contexts/detailsContext";

//Hooks
import useGetSystems from "../hooks/useGetSystems";

const useGetParameterGroups = () => {
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const detailsContext = useContext(DetailsContext);
  const setCurrentParameterGroups = detailsContext.setCurrentParameterGroups;

  const selectedTemplate = detailsContext.selectedTemplate;

  const getParameterGroups = useCallback(async () => {
    if (currentSubSystem && selectedTemplate) {
      const parameterTypes = await getTemplateById(selectedTemplate.id);
      const assetIds = currentSubSystem.assets.map((asset) => {
        return asset.id;
      });
      const parametersFromDDB = await getSelectedParameters(
        currentProject.project_id,
        assetIds
      );

      const newParameterTypes = parameterTypes.data.sub_groups.map((group1) => {
        const massLoading = false;

        const assembledParameterTypes = group1.eds_parameter_types.map(
          (parameterType) => {
            let parameters = [];
            assetIds.forEach((assetId, index) => {
              parameters.push({
                name: "no value",
                id: Math.random().toString(36).substring(2, 17) + index,
              });
            });
            parametersFromDDB.forEach((param) => {
              if (param.parameter_type.id === parameterType.parameter_type_id) {
                const index = assetIds.indexOf(param.parents[0].id);
                if (index > -1) {
                  parameters[index] = param;
                }
              }
            });
            return { ...parameterType, parameters };
          }
        );

        const assembledSubGroups = group1.sub_groups.map((subGroup) => {
          const assembledParameterTypes = subGroup.eds_parameter_types.map(
            (parameterType) => {
              let parameters = [];
              assetIds.forEach((assetId, index) => {
                parameters.push({
                  name: "no value",
                  id: Math.random().toString(36).substring(2, 17) + index,
                });
              });
              parametersFromDDB.forEach((param) => {
                if (
                  param.parameter_type.id === parameterType.parameter_type_id
                ) {
                  const index = assetIds.indexOf(param.parents[0].id);
                  if (index > -1) {
                    parameters[index] = param;
                  }
                }
              });
              return { ...parameterType, parameters };
            }
          );
          return { ...subGroup, eds_parameter_types: assembledParameterTypes };
        });

        return {
          ...group1,
          eds_parameter_types: assembledParameterTypes,
          sub_groups: assembledSubGroups,
          massLoading,
        };
      });
      setCurrentParameterGroups(newParameterTypes);
    }
  }, [
    selectedTemplate,
    currentProject,
    currentSubSystem,
    setCurrentParameterGroups,
  ]);

  return { getParameterGroups };
};

export default useGetParameterGroups;
