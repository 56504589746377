// Material UI Components
import { Box } from "@mui/material";

// Project Components
import Notes from "./notes/notes";

// Context
import { useContext, Fragment } from "react";
import { DetailsContext } from "../../contexts/detailsContext";

// Hooks
import useBlacklistFilter from "./use-hooks";
import useGetSystems from "../../hooks/useGetSystems";

function PrintPreview() {
  const detailsContext = useContext(DetailsContext);
  const currentParameterGroups = detailsContext.currentParameterGroups;

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const assets = currentSubSystem.assets;

  const { blackList } = useBlacklistFilter(detailsContext);

  const assetHeader = () => {
    return (
      <thead>
        <tr>
          <th></th>
          {assets.map((asset) => (
            <th colSpan={3} key={asset.id}>
              {asset.name}
            </th>
          ))}
        </tr>
        <tr>
          <th></th>
          {assets.map((asset) => (
            <Fragment key={asset.id}>
              <th>Required</th>
              <th>Offered</th>
              <th></th>
            </Fragment>
          ))}
        </tr>
      </thead>
    );
  };

  const groupTableBody = (rawGroupItems) => {
    const groupItem = rawGroupItems.filter(
      (g) => !blackList.includes(g.parameter_type_id)
    );

    return (
      <>
        {groupItem?.map((group, i) => (
          <tr key={group?.parameter_type_id + i}>
            <td width={"25%"}>{group?.title}</td>
            {group.parameters?.map((parameter, j) => (
              <Fragment key={parameter.id}>
                <td
                  key={j}
                  style={{ border: "solid 1px gray", textAlign: "center" }}
                >
                  {parameter?.revision?.values[0].value
                    ? `${parameter?.revision?.values[0].value} ${
                        parameter?.revision?.values[0].unit?.name || ""
                      }`
                    : "-"}
                </td>
                <td
                  style={{
                    border: "solid 1px gray",
                    textAlign: "center",
                  }}
                ></td>
                <td></td>
              </Fragment>
            ))}
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      {currentParameterGroups && (
        <Box id="printBox" sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
          {currentParameterGroups?.map((parameterGroup) => (
            <Fragment key={parameterGroup.id}>
              <h1
                style={{
                  color: "#E61E28",
                  margin: "25px 0px 0px 0px",
                  padding: "20px 0px 20px 0px",
                }}
              >
                {parameterGroup.title}{" "}
              </h1>
              <table
                width="100%"
                cellSpacing={"0px"}
                cellPadding={"5px"}
                style={{ borderCollapse: "collapse" }}
              >
                {assetHeader()}
                <tbody>
                  {groupTableBody(parameterGroup.eds_parameter_types)}
                </tbody>
              </table>
              {parameterGroup.sub_groups?.map((subgroup, i) => (
                <Fragment key={subgroup.id}>
                  <h2
                    style={{
                      margin: "25px 0px 0px 0px",
                      padding: "20px 0px 20px 0px",
                    }}
                  >
                    {subgroup.title}
                  </h2>
                  <table
                    width="100%"
                    cellSpacing={"0px"}
                    cellPadding={"5px"}
                    style={{ borderCollapse: "collapse" }}
                  >
                    {assetHeader()}
                    <tbody>
                      {groupTableBody(subgroup.eds_parameter_types)}
                    </tbody>
                  </table>
                </Fragment>
              ))}
            </Fragment>
          ))}
          <Notes />
        </Box>
      )}
    </>
  );
}

export default PrintPreview;
