// MUI
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";

// React
import { useState, useEffect, useContext } from "react";

// Prop types
import PropTypes from "prop-types";

// API
import { updateParameterValue } from "../../../../api/ddb/parameterService";

// Context
import { DetailsContext } from "../../../../contexts/detailsContext";
import { ApiErrorContext } from "../../../../contexts/apiErrorContext";

// Hooks
import useParameters from "../../../../hooks/useParameters";
import useGetParameterGroups from "../../../../hooks/useGetParameterGroups";

// Utils
import { renderUnitGroup } from "../../../../utils/renderUnitGroup";

function EditParameter(props) {
  const detailsContext = useContext(DetailsContext);
  const currentParameter = detailsContext.currentParameter;
  const setCurrentParameter = detailsContext.setCurrentParameter;
  const [newParameterValue, setNewParameterValue] = useState("");
  const [newParameterUnit, setNewParameterUnit] = useState("");
  const { parameterRawValue, parameterUnit, units } = props;
  const [isNewParameterValueInvalid, setIsNewParameterValueInvalid] =
    useState(true);
  const [newParameterHelpText, setNewParameterHelpText] = useState();
  const [loading, setLoading] = useState(false);
  const { getAllParameters } = useParameters();
  const { getParameterGroups } = useGetParameterGroups();

  const apiErrorContext = useContext(ApiErrorContext);
  const setErrorMessage = apiErrorContext.setErrorMessage;

  useEffect(() => {
    if (currentParameter) {
      setNewParameterValue(parameterRawValue);
    }
  }, [currentParameter, parameterRawValue]);

  useEffect(() => {
    if (units) {
      units.unit_type.unit_systems.forEach((unitSystem) => {
        unitSystem.units.forEach((unit) => {
          if (unit.name === parameterUnit) {
            setNewParameterUnit(unit);
          }
        });
      });
    }
  }, [units, parameterUnit]);

  useEffect(() => {
    // haven't checked for date type as there don't seem to actually
    // be any parameters in ddb which take dates?
    // also no boolean check because there is a dropdown for booleans
    // and no string check because we don't know specifically what
    // should be valid for a given parameter
    const parameterValueCheck = (textValue) => {
      if (currentParameter) {
        if (currentParameter.parameter_type.data_type === "integer") {
          const valid = !Number.isInteger(Number(textValue));
          setIsNewParameterValueInvalid(valid);
          if (!valid) {
            setNewParameterHelpText("Parameter value must be an integer");
          }
        } else if (
          currentParameter.parameter_type.data_type === "number" ||
          currentParameter.parameter_type.data_type === "float"
        ) {
          const invalid = isNaN(textValue);
          setIsNewParameterValueInvalid(invalid);
          if (invalid) {
            setNewParameterHelpText("Parameter value must be numeric");
          } else {
            setNewParameterHelpText();
          }
        } else {
          setNewParameterHelpText();
          setIsNewParameterValueInvalid(false);
        }
      }
    };
    parameterValueCheck(newParameterValue);
  }, [newParameterValue, currentParameter]);

  return (
    <>
      <Box sx={{ pl: 5, pt: 2, display: "flex" }}>
        {currentParameter.parameter_type.data_type === "boolean" &&
        typeof newParameterValue === "boolean" ? (
          // second check prevents console error when selecting boolean parameter
          // whilst another parameter is being edited
          <Select
            disabled={loading}
            size="small"
            label="Value"
            value={newParameterValue}
            onChange={(e) => setNewParameterValue(Boolean(e.target.value))}
            sx={{ width: "40%", maxWidth: 400 }}
          >
            <MenuItem value={true}>true</MenuItem>
            <MenuItem value={false}>false</MenuItem>
          </Select>
        ) : (
          <TextField
            disabled={loading}
            autoFocus
            variant="outlined"
            size="small"
            value={newParameterValue}
            label="Value"
            onChange={(e) => {
              setNewParameterValue(e.target.value);
            }}
            error={isNewParameterValueInvalid}
            helperText={newParameterHelpText}
            sx={{ width: "40%", maxWidth: 400 }}
          />
        )}
        {units ? (
          <Select
            disabled={loading}
            size="small"
            // label="Unit"
            value={newParameterUnit}
            onChange={(e) => setNewParameterUnit(e.target.value)}
            sx={{ width: "40%", maxWidth: 400 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
          >
            {units.unit_type.unit_systems.map((unitSystem) =>
              renderUnitGroup(unitSystem)
            )}
          </Select>
        ) : null}
        {!loading ? (
          <Tooltip title="Update parameter">
            <span>
              <IconButton
                disabled={isNewParameterValueInvalid}
                onClick={async () => {
                  setLoading(true);
                  const updated = await updateParameterValue(
                    currentParameter.id,
                    newParameterValue,
                    newParameterUnit.id
                  );

                  if (!updated.success) {
                    setErrorMessage(
                      `The parameter could not be updated (${updated?.response.status} server error)`
                    );
                  }

                  const newParameters = await getAllParameters();
                  newParameters.forEach((parameter) => {
                    if (parameter.id === currentParameter.id) {
                      setCurrentParameter(parameter);
                    }
                  });
                  getParameterGroups();
                }}
              >
                <Check
                  sx={
                    !isNewParameterValueInvalid
                      ? { color: "primary.dark" }
                      : { color: "none" }
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Box
            sx={{
              pl: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      <Typography variant="caption" sx={{ pl: 5 }}>
        {`All parameters are assigned
        a data source of 'eds_ui'.`}
      </Typography>
    </>
  );
}

EditParameter.propTypes = {
  setEditingParameter: PropTypes.func,
  parameterRawValue: PropTypes.any,
  parameterUnit: PropTypes.any,
  units: PropTypes.any,
};

export default EditParameter;
