import { useEffect } from "react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { getToken } from "../auth";
import { getAvatar, getUserInformation } from "../api/userRetrieval";
import { setUser } from "../redux-store/userSlice";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

function UserProvider(props) {
  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();

  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  instance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      instance.setActiveAccount(account);
    }
  });

  useEffect(() => {
    const getUserDetails = async () => {
      const token = await getToken();
      const avatarRes = await getAvatar(token);
      const userData = await getUserInformation(token);
      const userObj = {
        employeeId: userData.id,
        avatar: avatarRes,
        firstName: userData.givenName,
        lastName: userData.surname,
        userName: userData.displayName,
        office: userData.officeLocation,
        jobTitle: userData.jobTitle,
        email: userData.userPrincipalName,
      };
      dispatch(setUser(userObj));
    };
    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      getUserDetails();
    }
  }, [inProgress, instance, dispatch]);

  return <>{props.children}</>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
