import axios from "axios";
import fileDownload from "js-file-download";

// No need for an EDS token at the moment

import { BASE_URL, getHeaders } from "./index";

export const postPrint = async (printData) => {
  const fileName =
    `${printData.project_name}_${printData.subsystem_name}`.replace(/\s/g, "_");

  const headers = await getHeaders();

  await axios
    .post(`${BASE_URL}/eds-print`, printData, { headers, responseType: "blob" })
    .then((res) => fileDownload(res.data, `${fileName}.pdf`));
};
