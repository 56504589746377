// API
import { postNote } from "../../../api/eds/edsNotes";
import { postQaStatus, postMultipleQaStatus } from "../../../api/eds/edsQa";

// Hooks
import useCurrentNotes from "../../../hooks/useCurrentNotes";
import useGetSystems from "../../../hooks/useGetSystems";

import { useState, useEffect, useContext } from "react";

// Redux
import { useSelector } from "react-redux";

// Context
import { DetailsContext } from "../../../contexts/detailsContext";

const useGroupingNotes = () => {
  const { getNotes } = useCurrentNotes();
  const user = useSelector((state) => state.user.user);

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const detailsContext = useContext(DetailsContext);
  const setCurrentNote = detailsContext.setCurrentNote;
  const setCurrentParameter = detailsContext.setCurrentParameter;

  const notes = detailsContext.notes;

  const [notesOpen, setNotesOpen] = useState(false);
  const [editNotes, setEditNotes] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newNote, setNewNote] = useState("");

  const addNote = async () => {
    const noteRes = await postNote(currentSubSystem.id, newTitle, newNote);
    if (noteRes.status === 201) {
      const qaRes = await postQaStatus(
        noteRes.data.id,
        currentSubSystem.id,
        "answered",
        user.userName,
        new Date(),
        ""
      );
      if (qaRes.status === 201) {
        getNotes();
        setEditNotes(false);
        setNewTitle("");
        setNewNote("");
      }
    }
  };

  const checkPercentagesAddUpTo100 = (percentageCounts) => {
    const totalPercentage = percentageCounts.reduce(
      (total, item) => total + item.percentage,
      0
    );

    if (isNaN(totalPercentage) || totalPercentage === 100) return;

    const difference = Math.abs(100 - totalPercentage);
    const index = percentageCounts.findIndex(
      (status) => status.percentage > difference
    );
    if (totalPercentage > 100) {
      percentageCounts[index].percentage -= difference;
    } else {
      percentageCounts[index].percentage += difference;
    }
  };

  const getNoteHeaderStatus = () => {
    const allStatus = notes.map((note) => {
      return note.qaData[0]?.status;
    });

    const counts = [
      {
        status: "unanswered",
        color: "secondary.main",
        count: allStatus.filter((item) => item === "unanswered").length,
      },
      {
        status: "rejected",
        color: "error.main",
        count: allStatus.filter((item) => item === "rejected").length,
      },
      {
        status: "answered",
        color: "error.main",
        count: allStatus.filter((item) => item === "answered").length,
      },
      {
        status: "checked",
        color: "warning.main",
        count: allStatus.filter((item) => item === "checked").length,
      },
      {
        status: "approved",
        color: "success.main",
        count: allStatus.filter((item) => item === "approved").length,
      },
    ];

    let percentageCounts = counts.map((item) => {
      let percentage = Math.round((item.count / allStatus.length) * 100);
      return { ...item, percentage };
    });

    checkPercentagesAddUpTo100(percentageCounts);

    return percentageCounts;
  };

  const handleSelectNote = (note) => {
    setCurrentNote(note);
    setCurrentParameter(null);
  };

  const massCheckApproveNotes = async (targetStatus) => {
    const lowestStatus = getNoteHeaderStatus();
    const noteIdsToUpdate = [];
    notes.forEach((note) => {
      if (note.qaData[0]?.status === lowestStatus) {
        noteIdsToUpdate.push(note.id);
      }
    });
    const qaObjects = noteIdsToUpdate.map((note) => {
      const qa = {
        note_id: note,
        asset_id: currentSubSystem.id,
        status: targetStatus,
        created_by_name: user.userName,
        created_at: new Date(),
        comment: "",
      };
      return qa;
    });
    const res = await postMultipleQaStatus(qaObjects);
    if (res.status === 201) {
      getNotes();
      setCurrentNote();
    }
  };

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return {
    notesOpen,
    setNotesOpen,
    notes,
    editNotes,
    setEditNotes,
    addNote,
    newTitle,
    setNewTitle,
    newNote,
    setNewNote,
    getNoteHeaderStatus,
    handleSelectNote,
    massCheckApproveNotes,
  };
};

export default useGroupingNotes;
