import { createTheme } from "@mui/material/styles";

const black = "#000000";
const white = "#FFFFFF";
const grey = "#e0e0e0";

const edsPalette = {
  teal: {
    light: "#6ad5d0",
    main: "#31a39f",
    dark: "#007471",
    //contrast text for dark should be white
    contrastText: black,
  },
  slate: {
    light: "#7d97ac",
    main: "#50697d",
    dark: "#253e51",
    contrastText: white,
  },
  red: {
    light: "#d9616e",
    main: "#a33043",
    dark: "#6f001d",
    // contrast text for light should be black
    contrastText: white,
  },
  orange: {
    light: "#ff7f40",
    main: "#cc4f10",
    dark: "#941d00",
    //contrast text for dark should be white
    contrastText: black,
  },
  blue: {
    light: "#67acd4",
    main: "#307da3",
    dark: "#005174",
    // contrast text for light should be black
    contrastText: white,
  },
  green: {
    light: "#67d584",
    main: "#30a356",
    dark: "#00732b",
    //contrast text for dark should be white
    contrastText: black,
  },
};

const theme = createTheme({
  palette: {
    common: {
      black: black,
      white: white,
      grey: grey,
    },
    primary: edsPalette.teal,
    secondary: edsPalette.slate,
    error: edsPalette.red,
    warning: edsPalette.orange,
    info: edsPalette.blue,
    success: edsPalette.green,
  },
});

export default theme;
