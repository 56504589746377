import PropTypes from "prop-types";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../auth/auth-provider";

// will also need the error/loading components
function ProtectedPage(props) {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      // errorComponent={ErrorComponent}
      // loadingComponent={LoadingComponent}
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
}

ProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedPage;
