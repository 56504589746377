import { createContext } from "react";
import socketio from "socket.io-client";
import PropTypes from "prop-types";

const socket = socketio.connect(
  "https://arup-bm-eds-api-test-backend04-api.azurewebsites.net"
);
const SocketContext = createContext();
function SocketProvider({ children }) {
  const value = { socket };
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
}

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SocketContext, SocketProvider };
