import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material UI Components
import {
  TextField,
  Grid,
  Typography,
  FormControl,
  Box,
  Divider,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";

function DocumentTitle(props) {
  const { setHeaderData, setFormValid } = props;

  const [statusDescription, setStatusDescription] = useState("");
  const [docNo, setDocNo] = useState("");
  const [revisionStatus, setRevisionStatus] = useState("");
  const [revisionComment, setRevisionComment] = useState("");
  const [revisionDate, setRevisionDate] = useState(new Date());
  const [author, setAuthor] = useState("");
  const [checker, setChecker] = useState("");
  const [approver, setApprover] = useState("");
  const [statusCode, setStatusCode] = useState("");

  const updateData = () => {
    setHeaderData({
      docNo,
      revisionStatus,
      revisionComment,
      revisionDate,
      author,
      checker,
      approver,
      statusCode,
      statusDescription,
    });

    //more sophisticated validation needed here!
    const valid =
      docNo.length > 0 &&
      revisionStatus.length > 0 &&
      revisionComment.length > 0 &&
      author.length > 0 &&
      checker.length > 0 &&
      approver.length > 0 &&
      statusCode.length > 0 &&
      statusDescription.length > 0;

    setFormValid(valid);
  };

  return (
    <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
      <Divider>
        <Typography variant="subtitle1" sx={{ pb: 1, pt: 1 }}>
          QA
        </Typography>
      </Divider>
      <Grid
        container
        id="docTitle"
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField
            required
            label="Document Reference"
            value={docNo}
            onChange={(event) => {
              setDocNo(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            label="Author"
            value={author}
            onChange={(event) => setAuthor(event.target.value)}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            label="Checker"
            value={checker}
            onChange={(event) => {
              setChecker(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            label="Approver"
            value={approver}
            onChange={(event) => {
              setApprover(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Divider>
        <Typography variant="subtitle1" sx={{ pb: 1, pt: 1 }}>
          Revision
        </Typography>
      </Divider>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <TextField
            required
            label="Revision"
            value={revisionStatus}
            onChange={(event) => {
              setRevisionStatus(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Comment"
            value={revisionComment}
            onChange={(event) => {
              setRevisionComment(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={revisionDate}
                onChange={(event) => setRevisionDate(event)}
                onBlur={() => updateData()}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Divider>
        <Typography variant="subtitle1" sx={{ pb: 1, pt: 1 }}>
          Status
        </Typography>
      </Divider>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <TextField
            required
            label="Code"
            value={statusCode}
            onChange={(event) => {
              setStatusCode(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            label="Description"
            value={statusDescription}
            onChange={(event) => {
              setStatusDescription(event.target.value);
            }}
            onBlur={() => updateData()}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

DocumentTitle.propTypes = {
  setHeaderData: PropTypes.func.isRequired,
  setFormValid: PropTypes.func.isRequired,
};

export default DocumentTitle;
