import { PublicClientApplication, LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const msalConfig = {
  auth: {
    clientId: "dcddad01-7ab5-451d-b48a-f05f27cde329",
    authority:
      "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea/",
    validateAuthority: true,
    redirectUri: `${window.location.origin}/projects/`,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookies: isIE || isEdge || isFirefox,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const ddbTokenRequest = {
  scopes: ["api://ddb-api/user_impersonation"],
};

export const edsApiTokenRequest = {
  scopes: ["api://dcddad01-7ab5-451d-b48a-f05f27cde329/user_impersonation"],
};

const msalInstance = new PublicClientApplication(msalConfig);

// logging this as will be useful for dispatching to redux
// assigning to variable so that it can be removed programmatically if necessary
// eslint-disable-next-line no-unused-vars
// const callbackId = msalInstance.addEventCallback((message) => {
//   console.log(message);
// });

export default msalInstance;
