// Material UI Components
import { Skeleton } from "@mui/material";

function GroupingSkeleton() {
  return (
    <>
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 1 }} />
    </>
  );
}

export default GroupingSkeleton;
