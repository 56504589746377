import PropTypes from "prop-types";

// Material UI Components
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Collapse,
  List,
  ListItem,
  Button,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  DescriptionOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Check,
  Close,
} from "@mui/icons-material";

// Hooks
import useParameterNotes from "./useParameterNotes";

function ParameterNotes(props) {
  const { currentParameter } = props;
  const {
    notesOpen,
    toggleNotesOpen,
    parameterNotes,
    removeNotes,
    addingNewNote,
    setAddingNewNote,
    newParameterNote,
    setNewParameterNote,
    postNote,
  } = useParameterNotes(currentParameter);

  return (
    <Box sx={{ pl: 4 }}>
      <Grid container direction="row" alignItems="center" sx={{ pt: 4 }}>
        <Grid item sx={{ height: "24px" }}>
          <DescriptionOutlined fontSize="medium" />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            gutterBottom
            display="inline"
            sx={{ pl: 2 }}
          >
            User Guidance
          </Typography>
        </Grid>
      </Grid>
      <IconButton
        onClick={() => {
          toggleNotesOpen();
        }}
        sx={{ ml: 3.5 }}
      >
        {notesOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <Typography variant="body2" display="inline">
        {parameterNotes.length} Notes
      </Typography>
      <Collapse in={notesOpen}>
        <List sx={{ pl: 5 }}>
          {parameterNotes.map((note) => (
            <ListItem
              key={note.id}
              sx={{ pl: 0 }}
              secondaryAction={
                <Tooltip title="Delete">
                  <IconButton edge="end" onClick={() => removeNotes(note)}>
                    <Delete sx={{ color: "error.main" }} />
                  </IconButton>
                </Tooltip>
              }
            >
              {note.description}
            </ListItem>
          ))}
          {!addingNewNote ? (
            <ListItem sx={{ pl: 0, pt: 0 }}>
              <Button onClick={() => setAddingNewNote(true)}>
                Add new note
              </Button>
            </ListItem>
          ) : (
            <Grid container direction="row">
              <Grid item>
                <TextField
                  autoFocus
                  variant="outlined"
                  size="small"
                  label="Note"
                  value={newParameterNote}
                  onChange={(event) => setNewParameterNote(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Add note">
                          <span>
                            <IconButton
                              disabled={!newParameterNote}
                              onClick={() => postNote()}
                            >
                              <Check
                                sx={
                                  newParameterNote
                                    ? { color: "primary.dark" }
                                    : { color: "none" }
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => setAddingNewNote(false)}
                    sx={{ ml: 1 }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </List>
      </Collapse>
    </Box>
  );
}

ParameterNotes.propTypes = {
  currentParameter: PropTypes.object,
};

export default ParameterNotes;
