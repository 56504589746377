// Material UI Components
import { Add, Check, Close } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";

// Prop types
import PropTypes from "prop-types";

// React
import { useState } from "react";

export default function AddSubSystem(props) {
  const {
    addingSubSystem,
    setAddingSubSystem,
    newSubSystem,
    setNewSubSystem,
    addAsset,
    system,
  } = props;

  const [loading, setLoading] = useState(false);

  return (
    <ListItem
      data-cy="nav-new-sub-system"
      sx={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {!addingSubSystem ? (
        <ListItemButton
          sx={{ pl: 2, pr: 2 }}
          onClick={() => setAddingSubSystem(true)}
        >
          <ListItemText
            primary="Add new sub system"
            sx={{ color: "primary.dark" }}
          />
          <ListItemIcon sx={{ minWidth: 24 }}>
            <Add sx={{ color: "primary.dark" }} />
          </ListItemIcon>
        </ListItemButton>
      ) : (
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ pl: 2, pt: "4px", pb: "4px" }}
          spacing={1}
        >
          <Grid item xs={10}>
            <TextField
              autoFocus
              data-cy="nav-new-sub-system-text"
              variant="outlined"
              size="small"
              value={newSubSystem}
              label="Sub system"
              onChange={(e) => {
                setNewSubSystem(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!loading ? (
                      <Tooltip title="Add subsystem">
                        {/* wrapper needed for disabled button in Tooltip */}
                        <span>
                          <IconButton
                            data-cy="nav-sub-system-add-button"
                            disabled={!newSubSystem}
                            onClick={async () => {
                              setLoading(true);
                              await addAsset(system, "subSystem");
                              setLoading(false);
                            }}
                          >
                            <Check
                              sx={
                                newSubSystem
                                  ? { color: "primary.dark" }
                                  : { color: "none" }
                              }
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Cancel">
              <IconButton onClick={() => setAddingSubSystem(false)}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
}

AddSubSystem.propTypes = {
  addingSubSystem: PropTypes.bool,
  setAddingSubSystem: PropTypes.func,
  newSubSystem: PropTypes.string,
  setNewSubSystem: PropTypes.func,
  addAsset: PropTypes.func,
  system: PropTypes.object,
};
