import axios from "axios";

import {
  BASE_URL,
  SYSTEM_TYPE_ID,
  SUB_SYSTEM_TYPE_ID,
  PRODUCT_TYPE_ID,
  EDS_UI_SOURCE_ID,
} from "./index";

import { getDdbToken } from "../../auth/auth";

export const getSystems = async (projectId) => {
  const token = await getDdbToken();
  let query = "";
  if (projectId) {
    query = "?project_id=" + projectId + "&asset_type_id=" + SYSTEM_TYPE_ID;
  }
  const systems = await axios
    .get(`${BASE_URL}/assets${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return systems;
};

export const getSubSystems = async (systems) => {
  const token = await getDdbToken();
  let query = "";
  if (systems) {
    const systemsString = encodeURIComponent(JSON.stringify(systems));
    query = `?parent_id=${systemsString}&show_deleted_assets=false`;
  }
  const subSystems = await axios
    .get(`${BASE_URL}/assets${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return subSystems;
};

export const getParameters = async (projectId) => {
  const token = await getDdbToken();
  const assetTypes = [SYSTEM_TYPE_ID, SUB_SYSTEM_TYPE_ID, PRODUCT_TYPE_ID];
  const assetTypesEncoded = encodeURIComponent(JSON.stringify(assetTypes));
  let query = "";
  if (projectId) {
    query = `?project_id=${projectId}&asset_type=${assetTypesEncoded}`;
  }
  const parameters = await axios
    .get(`${BASE_URL}/parameters${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return parameters.parameters;
};

export const getSelectedParameters = async (projectId, assetIDs) => {
  const token = await getDdbToken();
  const assetTypes = [SYSTEM_TYPE_ID, SUB_SYSTEM_TYPE_ID, PRODUCT_TYPE_ID];
  const assetIDsEncoded = encodeURIComponent(JSON.stringify(assetIDs));
  const assetTypesEncoded = encodeURIComponent(JSON.stringify(assetTypes));
  let query = "";
  if (projectId) {
    query = `?project_id=${projectId}&asset_type=${assetTypesEncoded}&asset_id=${assetIDsEncoded}`;
  }
  const parameters = await axios
    .get(`${BASE_URL}/parameters${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return parameters.parameters;
};

export const addParameter = async (
  projectId,
  parameterTypeId,
  parentId,
  value,
  unitId
  // sourceId
) => {
  const token = await getDdbToken();
  const valueArray = [{ value: value, unit_id: unitId ? unitId : null }];
  const parameter = await axios
    .post(
      `${BASE_URL}/parameters`,
      {
        parameters: [
          {
            parameter_type_id: parameterTypeId,
            parent_ids: [parentId],
            project_id: projectId,
            revision: {
              source_id: EDS_UI_SOURCE_ID,
              values: valueArray,
            },
          },
        ],
      },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => res.data);
  return parameter.parameters[0];
};

export const deleteParameter = async (parameterId) => {
  const token = await getDdbToken();
  const res = await axios.delete(`${BASE_URL}/parameters/${parameterId}`, {
    headers: {
      Authorization: token,
      Version: "0",
    },
  });
  return res;
};

export const rejectParameter = async (parameterId, comment) => {
  const token = await getDdbToken();
  const res = await axios
    .patch(
      `${BASE_URL}/parameters/${parameterId}/revision`,
      { comment, status: "rejected" },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => {
      return res;
    });
  return res;
};

export const updateStatus = async (parameterId, qaStatus) => {
  const token = await getDdbToken();
  const res = await axios
    .patch(
      `${BASE_URL}/parameters/${parameterId}/revision`,
      { status: qaStatus },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => {
      return res;
    });
  return res;
};

export const updateParameterValue = async (parameterId, value, unitId) => {
  const token = await getDdbToken();
  const res = await axios
    .post(
      `${BASE_URL}/parameters/${parameterId}/revision`,
      {
        source_id: EDS_UI_SOURCE_ID,
        values: [{ value: value, unit_id: unitId }],
      },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => {
      return { ...res, success: true };
    })
    .catch((error) => {
      return { ...error, success: false };
    });
  return res;
};

export const getRevisions = async (parameterId) => {
  const token = await getDdbToken();
  const revisions = await axios
    .get(`${BASE_URL}/parameters/${parameterId}/revision`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return revisions;
};

export const getParameterTypes = async (assetTypeId) => {
  const token = await getDdbToken();
  const query = `?asset_type_id=${assetTypeId}`;
  const parameterTypes = await axios
    .get(`${BASE_URL}/parameter_types${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return parameterTypes;
};

export const getAssetsByType = async (assetTypeId, projectId) => {
  const token = await getDdbToken();
  let query = "";
  if (assetTypeId && projectId) {
    query = `?asset_type_id=${assetTypeId}&project_id=${projectId}&show_deleted_assets=false`;
  }
  const assets = await axios
    .get(`${BASE_URL}/assets${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return assets;
};

export const renameAsset = async (assetId, newName) => {
  const token = await getDdbToken();
  const updatedAsset = await axios
    .patch(
      `${BASE_URL}/assets/${assetId}`,
      {
        name: newName,
      },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => res.data);
  return updatedAsset;
};

export const deleteAsset = async (assetId) => {
  const token = await getDdbToken();
  const res = await axios.delete(`${BASE_URL}/assets/${assetId}`, {
    headers: {
      Authorization: token,
      Version: "0",
    },
  });
  return res;
};

export const postAsset = async (
  assetTypeId,
  projectId,
  assetName,
  parentId
) => {
  const token = await getDdbToken();
  const res = await axios
    .post(
      `${BASE_URL}/assets`,
      {
        assets: [
          {
            asset_type_id: assetTypeId,
            project_id: projectId,
            name: assetName,
            parent_id: parentId,
          },
        ],
      },
      {
        headers: {
          Authorization: token,
          Version: "0",
        },
      }
    )
    .then((res) => {
      return res;
    });
  return res;
};

export const getUnits = async (unitTypeId) => {
  const token = await getDdbToken();
  const units = await axios
    .get(`${BASE_URL}/unit_types/${unitTypeId}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return units;
};
