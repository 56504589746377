import { Box, Skeleton, ListItem, Divider } from "@mui/material";

function SkeletonParameterItem() {
  return (
    <Box>
      <ListItem sx={{ height: "88px" }}>
        {/* multiplication thing because skeleton CSS includes transform scale 0.6 */}
        <Skeleton width="32px" height={`${32 * (1 / 0.6)}px`} />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            maxWidth: 500,
            justifyContent: "space-between",
            paddingLeft: "1rem",
          }}
        >
          <Box sx={{ height: "100%", paddingTop: "3px" }}>
            <Skeleton width="30vw" height="20px" sx={{ maxWidth: "200px" }} />
            <Skeleton width="30vw" height="20px" sx={{ maxWidth: "200px" }} />
          </Box>
          <Box sx={{ height: "100%", paddingTop: "3px" }}>
            <Skeleton width="30vw" height="20px" sx={{ maxWidth: "200px" }} />
            <Skeleton width="30vw" height="20px" sx={{ maxWidth: "200px" }} />
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </Box>
  );
}

export default SkeletonParameterItem;
