// React
import { useEffect, useState, useCallback } from "react";

// API
import {
  getRevisions,
  updateStatus,
} from "../../../../api/ddb/parameterService";
import { getRecords } from "../../../../api/ddb/qaService";
import { getComment } from "../../../../api/ddb/commentsService";
import { getUnits } from "../../../../api/ddb/parameterService";

// Utils
import {
  displayParameterStatusIcon,
  displayParameterName,
  displayParameterValue,
  displayParameterRawValue,
  displayParameterUnit,
  displayParameterParentAsset,
  displayParameterVersionHeader,
  displayParameterStatusHeader,
} from "../../../../utils/parameterDisplayDetails";

// Hooks
import useReloadParameters from "../../useReloadParameters";
import useGetParameterGroups from "../../../../hooks/useGetParameterGroups";

const useParameterDetails = (parameter) => {
  const [versionOpen, setVersionOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [revisions, setRevisions] = useState(null);
  const [records, setRecords] = useState(null);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [comment, setComment] = useState({});
  const [deleteParameterOpen, setDeleteParameterOpen] = useState(false);
  const [parameterCheckApproveLoading, setParameterCheckApproveLoading] =
    useState(false);

  const { reloadParams } = useReloadParameters();
  const { getParameterGroups } = useGetParameterGroups();

  const parameterStatusIcon = parameter
    ? displayParameterStatusIcon(parameter)
    : null;
  const parameterName = parameter ? displayParameterName(parameter) : null;
  const parameterValue = parameter ? displayParameterValue(parameter) : null;
  const parameterRawValue = parameter
    ? displayParameterRawValue(parameter)
    : null;
  const parameterUnit = parameter ? displayParameterUnit(parameter) : null;
  const parameterParentAsset = parameter
    ? displayParameterParentAsset(parameter)
    : null;
  const versionHeader = parameter
    ? displayParameterVersionHeader(parameter.revision)
    : null;
  const statusHeader = records ? displayParameterStatusHeader(records) : null;
  // const statusHeader = null;

  const toggleVersionOpen = () => {
    setVersionOpen(!versionOpen);
  };

  const toggleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  const getAllRevisions = useCallback(async () => {
    if (parameter) {
      setRevisions();
      const newRevisions = await getRevisions(parameter.id);
      setRevisions(newRevisions.revisions);
    }
  }, [parameter]);

  const getAllRecords = useCallback(async () => {
    if (parameter) {
      setRecords();
      const newRecords = await getRecords(parameter.revision.id);
      setRecords(newRecords.records);
    }
  }, [parameter]);

  useEffect(() => {
    getAllRevisions();
    getAllRecords();
  }, [getAllRevisions, getAllRecords]);

  const handleCheckApprove = async (qaStatus) => {
    setParameterCheckApproveLoading(true);
    const res = await updateStatus(parameter.id, qaStatus);
    if (res.status === 200) {
      await reloadParams(parameter.id);
      await getParameterGroups();
      setParameterCheckApproveLoading(false);
    }
  };

  const handleComment = useCallback(async () => {
    const newComment = await getComment(parameter.revision?.comment);
    setComment(newComment);
  }, [parameter]);

  useEffect(() => {
    if (parameter?.revision?.status === "rejected") {
      handleComment();
    }
  }, [parameter, handleComment]);

  const [editingParameter, setEditingParameter] = useState(false);
  useEffect(() => {
    if (parameter) {
      setEditingParameter(false);
    }
  }, [parameter]);

  const [units, setUnits] = useState();

  const getCurrentUnits = useCallback(async () => {
    if (parameter.parameter_type.unit_type_id) {
      const currentUnits = await getUnits(
        parameter.parameter_type.unit_type_id
      );
      setUnits(currentUnits);
    } else {
      setUnits();
    }
  }, [parameter, setUnits]);

  useEffect(() => {
    if (parameter) {
      getCurrentUnits();
    }
  }, [parameter, getCurrentUnits]);

  return {
    versionOpen,
    toggleVersionOpen,
    statusOpen,
    toggleStatusOpen,
    revisions,
    records,
    parameterStatusIcon,
    parameterName,
    parameterValue,
    parameterRawValue,
    parameterUnit,
    parameterParentAsset,
    versionHeader,
    statusHeader,
    rejectModalOpen,
    setRejectModalOpen,
    handleCheckApprove,
    comment,
    deleteParameterOpen,
    setDeleteParameterOpen,
    parameterCheckApproveLoading,
    editingParameter,
    setEditingParameter,
    units,
  };
};

export default useParameterDetails;
