// Project Components
import DetailsContent from "./detailsContent/detailsContent";

// Hooks
import useDetails from "./use-hooks";

// Material UI Components
import { Toolbar, Box } from "@mui/material";

// React
import { useRef, useContext } from "react";

// Context
import { DetailsContext } from "../../contexts/detailsContext";

function DetailsPanel() {
  const drawerRef = useRef();
  const paperRef = useRef();

  const detailsContext = useContext(DetailsContext);
  const showPrintPreview = detailsContext.showPrintPreview;

  const { Drawer, handleMouseDown, open, smallScreenBack, smallScreen } =
    useDetails(drawerRef, paperRef);

  return (
    <Drawer
      variant={open ? "permanent" : "temporary"}
      open={open}
      anchor="right"
      ref={drawerRef}
      PaperProps={{ ref: paperRef }}
    >
      <Toolbar />
      <Box
        sx={{
          width: "5px",
          height: "100%",
          position: "absolute",
          left: 0,
          cursor: "col-resize",
        }}
        onMouseDown={handleMouseDown}
      />
      {!showPrintPreview && (
        <DetailsContent
          smallScreenBack={smallScreenBack}
          smallScreen={smallScreen}
        />
      )}
    </Drawer>
  );
}

export default DetailsPanel;
