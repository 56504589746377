// Material UI Components
import { AppBar, Toolbar, Box, IconButton, Tooltip } from "@mui/material";
import { LiveHelpOutlined } from "@mui/icons-material";

// Components
import HomeButton from "./homeButton/homeButton";
import UserAvatar from "./userAvatar/userAvatar";
import UserGuidance from "./userGuidance/userGuidance";

import { useState } from "react";

function Header() {
  const [userGuideOpen, setUserGuideOpen] = useState(false);

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ zIndex: 1400, borderBottom: 2, borderColor: "secondary.main" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 1rem 0 0",
          }}
          disableGutters={true}
        >
          <HomeButton />
          <Box>
            <Tooltip title={userGuideOpen ? "Close User Guide" : "User Guide"}>
              <IconButton
                onClick={() => setUserGuideOpen(!userGuideOpen)}
                sx={{ mr: 2 }}
              >
                <LiveHelpOutlined
                  sx={{ color: "common.black" }}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
            <UserAvatar />
          </Box>
        </Toolbar>
      </AppBar>
      <UserGuidance
        userGuideOpen={userGuideOpen}
        setUserGuideOpen={setUserGuideOpen}
      />
    </>
  );
}

export default Header;
