import axios from "axios";

import { BASE_URL } from "./index";

import { getDdbToken } from "../../auth/auth";

export const getProjects = async (queryParams) => {
  const token = await getDdbToken();
  let query = "";
  if (queryParams) {
    query = "?" + queryParams + "&page_limit=100000";
  }
  const projects = await axios
    .get(`${BASE_URL}/projects${query}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return projects;
};

export const getProjectById = async (projectId) => {
  const token = await getDdbToken();
  const project = await axios
    .get(`${BASE_URL}/projects/${projectId}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return project.project;
};
