import PropTypes from "prop-types";

// Material UI Components
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Divider,
  Collapse,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Skeleton,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  ArrowBack,
  FileCopy,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Subject,
  History,
  CheckCircleOutline,
  PendingOutlined,
  DangerousOutlined,
  Edit,
  Close,
  Delete,
} from "@mui/icons-material";

// Utils
import selectIcon from "../../../../utils/selectIcon";
import formatDate from "../../../../utils/formatDate";
import { displayParameterValue } from "../../../../utils/parameterDisplayDetails";

// Hooks
import useParameterDetails from "./useParameterDetails";

// Project Components
import RejectModal from "../rejectModal/rejectModal";
import EditParameter from "./editParameter";
import ParameterNotes from "../parameterNotes/parameterNotes";
import DeleteParameter from "../deleteParameter/deleteParameter";

function ParameterDetails(props) {
  const { smallScreenBack, smallScreen, currentParameter } = props;

  const {
    versionOpen,
    toggleVersionOpen,
    statusOpen,
    toggleStatusOpen,
    revisions,
    records,
    parameterStatusIcon,
    parameterName,
    parameterValue,
    parameterRawValue,
    parameterUnit,
    parameterParentAsset,
    versionHeader,
    statusHeader,
    rejectModalOpen,
    setRejectModalOpen,
    handleCheckApprove,
    comment,
    deleteParameterOpen,
    setDeleteParameterOpen,
    parameterCheckApproveLoading,
    editingParameter,
    setEditingParameter,
    units,
  } = useParameterDetails(currentParameter);

  return (
    <>
      <Box>
        <Grid
          container
          id="header"
          direction="row"
          alignItems="center"
          sx={{ py: 2 }}
          data-cy="parameter-detail-header"
        >
          <Grid item xs={12} sm={8}>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                sx={{
                  px: 4,
                  height: "35px",
                }}
              >
                <Tooltip title={currentParameter.revision?.status}>
                  {parameterStatusIcon}
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h6">{parameterName}</Typography>
                <Typography
                  variant="subtitle1"
                  data-cy="parameter-detail-subtitle"
                >
                  {parameterParentAsset}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ float: "right", pr: 3 }}>
              {smallScreen ? (
                <IconButton onClick={() => smallScreenBack()}>
                  <ArrowBack sx={{ color: "primary.dark" }} />
                </IconButton>
              ) : null}
              <Tooltip title="copy parameter id">
                <span>
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(currentParameter.id)
                    }
                    disabled={editingParameter}
                  >
                    <FileCopy
                      sx={{
                        color: editingParameter ? "none" : "primary.dark",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              {!editingParameter ? (
                <Tooltip title="edit">
                  <span>
                    <IconButton onClick={() => setEditingParameter(true)}>
                      <Edit sx={{ color: "primary.dark" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="cancel">
                  <span>
                    <IconButton onClick={() => setEditingParameter(false)}>
                      <Close sx={{ color: "primary.dark" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip title="delete">
                <span>
                  <IconButton
                    onClick={() => setDeleteParameterOpen(true)}
                    disabled={editingParameter}
                  >
                    <Delete
                      sx={{
                        color: editingParameter ? "none" : "primary.dark",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              {currentParameter.revision?.status !== "rejected" ? (
                <Tooltip title="reject">
                  <span>
                    <IconButton
                      data-cy="parameter-reject-button"
                      onClick={() => setRejectModalOpen(true)}
                      disabled={editingParameter}
                    >
                      <DangerousOutlined
                        sx={{
                          color: editingParameter ? "none" : "primary.dark",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
              {currentParameter.revision?.status === "answered" ? (
                <Tooltip
                  title={parameterCheckApproveLoading ? "checking..." : "check"}
                >
                  <span>
                    {parameterCheckApproveLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "primary.dark", mb: -0.5, mx: 1.5 }}
                      />
                    ) : (
                      <IconButton
                        data-cy="parameter-check-button"
                        onClick={() => handleCheckApprove("checked")}
                        disabled={editingParameter}
                      >
                        <PendingOutlined
                          sx={{
                            color: editingParameter ? "none" : "primary.dark",
                          }}
                        />
                      </IconButton>
                    )}
                  </span>
                </Tooltip>
              ) : null}
              {currentParameter.revision?.status === "checked" ? (
                <Tooltip
                  title={
                    parameterCheckApproveLoading ? "approving..." : "approve"
                  }
                >
                  <span>
                    {parameterCheckApproveLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "primary.dark", mb: -0.5, mx: 1.5 }}
                      />
                    ) : (
                      <IconButton
                        data-cy="parameter-approve-button"
                        onClick={() => handleCheckApprove("approved")}
                        disabled={editingParameter}
                      >
                        <CheckCircleOutline
                          sx={{
                            color: editingParameter ? "none" : "primary.dark",
                          }}
                        />
                      </IconButton>
                    )}
                  </span>
                </Tooltip>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {currentParameter.revision?.status === "rejected" ? (
          <Alert severity="error">
            {comment?.comment ? (
              <>
                <Typography sx={{ fontWeight: "bold" }}>
                  Rejected by {comment.comment.created_by.staff_name} on{" "}
                  {formatDate(comment.comment.created_at)}
                </Typography>
                <Typography data-cy="popup-reject-comment-button">
                  Comment: {comment?.comment?.content}
                </Typography>
              </>
            ) : (
              <>
                <Skeleton sx={{ width: 200 }} />
                <Skeleton sx={{ width: 200 }} />
              </>
            )}
          </Alert>
        ) : null}
        <Box id="content" sx={{ pl: 4 }}>
          <Grid container direction="row" alignItems="center" sx={{ pt: 5 }}>
            <Grid item sx={{ height: "24px" }}>
              <Subject fontSize="medium" />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                gutterBottom
                display="inline"
                sx={{ pl: 2 }}
              >
                Value
              </Typography>
            </Grid>
          </Grid>
          {!editingParameter ? (
            <Typography variant="body2" sx={{ pl: 5, pt: 1 }}>
              {parameterValue}
            </Typography>
          ) : (
            <EditParameter
              parameterRawValue={parameterRawValue}
              parameterUnit={parameterUnit}
              units={units}
            />
          )}
          <Box id="versionHistory">
            <Grid container direction="row" alignItems="center" sx={{ pt: 5 }}>
              <Grid item sx={{ height: "24px" }}>
                <History fontSize="medium" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  gutterBottom
                  display="inline"
                  sx={{ pl: 2 }}
                >
                  Version History
                </Typography>
              </Grid>
            </Grid>
            <IconButton
              onClick={() => {
                toggleVersionOpen();
              }}
              sx={{ ml: 3.5 }}
            >
              {versionOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Typography variant="body2" display="inline">
              {versionHeader}
            </Typography>
            <Collapse in={versionOpen}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Author</TableCell>
                    <TableCell align="left">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revisions ? (
                    revisions.map((rev) => (
                      <TableRow key={rev.id}>
                        <TableCell>
                          <Tooltip title={rev.status || "undefined"}>
                            {selectIcon(rev.status, "medium")}
                          </Tooltip>
                        </TableCell>
                        <TableCell>{formatDate(rev.created_at)}</TableCell>
                        <TableCell>{rev.created_by?.staff_name}</TableCell>
                        <TableCell>
                          {displayParameterValue({ revision: rev })}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Skeleton>
                          <CheckCircleOutline />
                        </Skeleton>
                      </TableCell>
                      <TableCell>
                        <Skeleton>
                          <Typography variant="body1">00/00/0000</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell>
                        <Skeleton>
                          <Typography variant="body1">First Last</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell>
                        <Skeleton>
                          <Typography variant="body1">000</Typography>
                        </Skeleton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Collapse>
          </Box>
          <Box id="statusHistory">
            <Grid container direction="row" alignItems="center" sx={{ pt: 4 }}>
              <Grid item sx={{ height: "24px" }}>
                <CheckCircleOutline fontSize="medium" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  gutterBottom
                  display="inline"
                  sx={{ pl: 2 }}
                >
                  Status History
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  toggleStatusOpen();
                }}
                sx={{ ml: 3.5 }}
              >
                {statusOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
              {statusHeader ? (
                <Typography variant="body2" display="inline">
                  {statusHeader}
                </Typography>
              ) : (
                <Skeleton>
                  <Typography variant="body2">
                    Checked by First Last on 00/00/0000
                  </Typography>
                </Skeleton>
              )}
            </Box>
            <Collapse in={statusOpen}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Updated by</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records ? (
                    records.map((rec) => (
                      <TableRow key={rec.id}>
                        <TableCell>
                          <Tooltip title={rec.type.name || "undefined"}>
                            {selectIcon(rec.type.name, "medium")}
                          </Tooltip>
                        </TableCell>
                        <TableCell>{formatDate(rec.created_at)}</TableCell>
                        <TableCell>{rec.created_by?.staff_name}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Skeleton>
                          <CheckCircleOutline />
                        </Skeleton>
                      </TableCell>
                      <TableCell>
                        <Skeleton>
                          <Typography variant="body1">00/00/0000</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell>
                        <Skeleton>
                          <Typography variant="body1">First Last</Typography>
                        </Skeleton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Collapse>
          </Box>
        </Box>
        <ParameterNotes currentParameter={currentParameter} />
      </Box>
      <RejectModal
        currentParameter={currentParameter}
        rejectModalOpen={rejectModalOpen}
        setRejectModalOpen={setRejectModalOpen}
      />
      <DeleteParameter
        currentParameter={currentParameter}
        open={deleteParameterOpen}
        onClose={() => {
          setDeleteParameterOpen(false);
        }}
      />
    </>
  );
}

ParameterDetails.propTypes = {
  smallScreenBack: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool.isRequired,
  currentParameter: PropTypes.any,
};

export default ParameterDetails;
