import { useEffect, useState } from "react";

// API
import { patchNote, deleteNote } from "../../../../api/eds/edsNotes";
import { postQaStatus, deleteQaByNoteId } from "../../../../api/eds/edsQa";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../../../contexts/detailsContext";

// Redux
import { useSelector } from "react-redux";

// Hooks
import useCurrentNotes from "../../../../hooks/useCurrentNotes";
import useGetSystems from "../../../../hooks/useGetSystems";

const useEdsNoteContent = () => {
  const detailsContext = useContext(DetailsContext);
  const currentNote = detailsContext.currentNote;
  const setCurrentNote = detailsContext.setCurrentNote;
  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const user = useSelector((state) => state.user.user);
  const { getNotes } = useCurrentNotes();

  const [statusHistoryOpen, setStatusHistoryOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleStatusHistoryOpen = () => {
    setStatusHistoryOpen(!statusHistoryOpen);
  };

  const handleCheckApprove = async (status) => {
    const currentId = currentNote.id;
    const res = await postQaStatus(
      currentNote.id,
      currentSubSystem.id,
      status,
      user.userName,
      new Date(),
      ""
    );
    if (res.status === 201) {
      const newNotes = await getNotes();
      newNotes.forEach((note) => {
        if (note.id === currentId) {
          setCurrentNote(note);
        }
      });
    }
  };

  const updateDescription = async () => {
    const currentId = currentNote.id;
    const res = await patchNote(currentNote.id, newDescription);
    if (res.status === 200) {
      const qaRes = await postQaStatus(
        currentNote.id,
        currentSubSystem.id,
        "answered",
        user.userName,
        new Date(),
        ""
      );
      if (qaRes.status === 201) {
        const newNotes = await getNotes();
        newNotes.forEach((note) => {
          if (note.id === currentId) {
            setCurrentNote(note);
          }
        });
        setEditingNote(false);
        setNewDescription("");
      }
    }
  };

  const removeNote = async (noteId) => {
    const res = await deleteNote(noteId);
    if (res.status === 200) {
      await deleteQaByNoteId(noteId);
      setCurrentNote();
      getNotes();
    }
  };

  useEffect(() => {
    setNewDescription(currentNote.description);
    setEditingNote(false);
  }, [currentNote]);

  return {
    statusHistoryOpen,
    toggleStatusHistoryOpen,
    handleCheckApprove,
    rejectModalOpen,
    setRejectModalOpen,
    editingNote,
    setEditingNote,
    newDescription,
    setNewDescription,
    loading,
    setLoading,
    updateDescription,
    removeNote,
  };
};

export default useEdsNoteContent;
