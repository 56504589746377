import { Button, Typography, Container, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "100%",
          transform: "translateY(-7vh)",
          maxWidth: {
            xl: "md",
            lg: "md",
            md: "sm",
            sm: "sm",
            xs: "xs",
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography variant="h3" sx={{ color: "primary.dark" }} gutterBottom>
            Asset Data Management
          </Typography>
          <Typography
            variant="h5"
            component="p"
            sx={{ color: "primary.dark" }}
            gutterBottom
          >
            A solution for managing Asset Data, integral to our projects
          </Typography>
          <Button
            variant="outlined"
            onClick={() => navigate("/projects")}
            sx={{
              color: "secondary.dark",
              borderColor: "secondary.dark",
              marginTop: "2rem",
            }}
            data-cy="go-to-projects"
          >
            Go to Projects
          </Button>
        </Box>
      </Container>
    </>
  );
}
