// MUI
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  // FilterList,
  Add,
  // ArrowUpward,
  // ArrowDownward,
} from "@mui/icons-material";

// React
import { useState } from "react";

// Prop types
import PropTypes from "prop-types";

// Project components
import AddParameter from "../addParameter/addParameter";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../contexts/detailsContext";

function PanelHeader(props) {
  // const [isSortAscending, setIsSortAscending] = useState(true);
  const { currentSystem, currentSubSystem } = props;
  const [addParameterOpen, setAddParameterOpen] = useState(false);
  const onClose = () => {
    setAddParameterOpen(false);
  };
  const detailsContext = useContext(DetailsContext);
  const setCurrentAsset = detailsContext.setCurrentAsset;

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ borderBottom: 2, borderColor: "secondary.light" }}
      >
        <Toolbar>
          <Typography data-cy="asset-panel-header" sx={{ fontWeight: "bold" }}>
            {currentSubSystem ? (
              <>ADM Grouping: {currentSubSystem.name}</>
            ) : currentSystem ? (
              <>System parameters: {currentSystem.name}</>
            ) : (
              "All Parameters"
            )}
          </Typography>
          <Box sx={{ flex: 1, textAlign: "right" }}>
            {/* Add and Sort Parameter funtionality will be added in a post-MVP update */}
            {/* <Tooltip title="Sort and filter">
            <IconButton>
              <FilterList />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sort direction">
            <IconButton
              onClick={() => {
                setIsSortAscending(!isSortAscending);
              }}
            >
              {isSortAscending ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          </Tooltip> */}
            <Tooltip title="Add parameter">
              <IconButton
                sx={{ color: "primary.dark" }}
                onClick={() => {
                  setCurrentAsset();
                  setAddParameterOpen(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <AddParameter open={addParameterOpen} onClose={onClose} />
    </>
  );
}

PanelHeader.propTypes = {
  currentSystem: PropTypes.object,
  currentSubSystem: PropTypes.object,
};

export default PanelHeader;
