import { useContext, useState } from "react";

// Utils
import stringArray from "../../../utils/stringAvatar";

// Material UI Components
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  CardHeader,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

//Contexts
import { DetailsContext } from "../../../contexts/detailsContext";

// Hooks
import useCurrentProject from "../../../hooks/useCurrentProject";
import useSockets from "../../../hooks/useSockets";

// Prop Types
import PropTypes from "prop-types";
function OtherUsers(props) {
  const { open } = props;
  const detailsContext = useContext(DetailsContext);
  const { currentProject } = useCurrentProject();
  useSockets(currentProject);

  const [openDetails, setOpenDetails] = useState(false);

  const { activeSocketUsers, showSocketUsers } = detailsContext;

  return (
    <>
      {!!showSocketUsers && open && (
        <>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenDetails(!openDetails);
              }}
            >
              <ListItemText primary={"Other users are viewing this project:"} />

              <AvatarGroup max={2}>
                {activeSocketUsers.map((item) => (
                  <Avatar
                    key={item.clientId}
                    {...stringArray(item.user.userName)}
                    src={item.user.avatar}
                  ></Avatar>
                ))}
              </AvatarGroup>
              {openDetails ? (
                <ExpandLess sx={{ color: "primary.dark" }} />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            {activeSocketUsers.map((item) => (
              <CardHeader
                key={item.clientId}
                avatar={
                  <Avatar
                    {...stringArray(item.user.userName)}
                    src={item.user.avatar}
                  ></Avatar>
                }
                title={item.user.userName}
              />
            ))}
          </Collapse>
        </>
      )}
    </>
  );
}

OtherUsers.propTypes = {
  open: PropTypes.bool,
};

export default OtherUsers;
