//React, router
import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

//Redux
import { setCurrentProject } from "../../redux-store/projectSlices";
import { setProjects } from "../../redux-store/projectSlices";
import { useDispatch, useSelector } from "react-redux";

//API Functions
import { getProjects } from "../../api/ddb/environmentContextService";

//Msal
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const useProjects = () => {
  const { instance, inProgress } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );
  let recentProjects = JSON.parse(localStorage.getItem("recentProjects"));
  if (!recentProjects) {
    localStorage.setItem("recentProjects", JSON.stringify([]));
  }

  const getDdbProjects = useCallback(async () => {
    const retrievedProjects = await getProjects("sort_by=number&order=desc");
    dispatch(setProjects(retrievedProjects.projects));
  }, [dispatch]);

  const handleCurrentProject = (project) => {
    dispatch(setCurrentProject(project));
  };

  const goToProject = () => {
    navigate(`/projects/${currentProject.project_id}/details`);
    addRecentProjects();
  };

  const addRecentProjects = () => {
    let recentProjects = JSON.parse(localStorage.getItem("recentProjects"));
    let inRecent = false;
    recentProjects.forEach((project) => {
      if (project.number === currentProject.number) {
        inRecent = true;
      }
    });
    if (!inRecent) {
      recentProjects.unshift(currentProject);
    }
    if (recentProjects.length > 5) {
      recentProjects.pop();
    }
    localStorage.setItem("recentProjects", JSON.stringify(recentProjects));
  };

  const goToRecent = (project) => {
    handleCurrentProject(project);
    navigate(`/projects/${project.project_id}/details`);
  };

  useEffect(() => {
    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      getDdbProjects();
    }
  }, [getDdbProjects, inProgress, instance]);

  return {
    handleCurrentProject,
    goToProject,
    goToRecent,
  };
};

export default useProjects;
