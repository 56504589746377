// Redux
import { useSelector } from "react-redux";

// React
import { useState, useContext, useEffect } from "react";

// API
import { postAsset } from "../../../api/ddb/parameterService";
import {
  SYSTEM_TYPE_ID,
  SUB_SYSTEM_TYPE_ID,
  PRODUCT_TYPE_ID,
} from "../../../api/ddb";

// Hooks
import useParameters from "../../../hooks/useParameters";
import useBuildings from "../../../hooks/useBuildings";
import useGetSystems from "../../../hooks/useGetSystems";

// Utils
import { getSystemAssetIDs } from "../../../utils/getAssetIDs";

// Context
import { DetailsContext } from "../../../contexts/detailsContext";

const useNavigationContent = (assembleSystems) => {
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );
  const { getAllParameters, getSomeParameters } = useParameters();

  const detailsContext = useContext(DetailsContext);
  const setCurrentSystemId = detailsContext.setCurrentSystemId;
  const setCurrentSubSystemId = detailsContext.setCurrentSubSystemId;
  const setCurrentNote = detailsContext.setCurrentNote;
  const setCurrentParameterGroups = detailsContext.setCurrentParameterGroups;
  const setCurrentParameter = detailsContext.setCurrentParameter;
  const setShowPrintPreview = detailsContext.setShowPrintPreview;
  const setSelectedTemplate = detailsContext.setSelectedTemplate;

  const currentSystemId = detailsContext.currentSystemId;
  const currentSubSystemId = detailsContext.currentSubSystemId;

  const currentParameter = detailsContext.currentParameter;
  const setCurrentOpenGroup = detailsContext.setCurrentOpenGroup;

  const { getCurrentSystem } = useGetSystems();

  useEffect(() => {
    setShowPrintPreview(false);
  }, [
    currentSystemId,
    currentSubSystemId,
    currentParameter,
    setShowPrintPreview,
  ]);

  useEffect(() => {
    setCurrentOpenGroup(null);
  }, [currentSystemId, currentSubSystemId, setCurrentOpenGroup]);

  const toggleSystem = (id) => {
    const newSystemId = id === currentSystemId ? null : id;
    setCurrentSystemId(newSystemId);
    setCurrentSubSystemId();
    setCurrentNote();
    setCurrentParameterGroups();
    setCurrentParameter();

    if (newSystemId) {
      const system = getCurrentSystem();
      if (system) {
        const assetIDs = getSystemAssetIDs(system);
        getSomeParameters(assetIDs);
      }
    } else {
      getAllParameters();
    }
  };

  const toggleSubSystem = (id) => {
    const newSubSystemId = id === currentSubSystemId ? null : id;
    setCurrentSubSystemId(newSubSystemId);
    setCurrentNote();
    setCurrentParameterGroups();
    setCurrentParameter();
  };

  const [addingAsset, setAddingAsset] = useState(false);
  const [newAsset, setNewAsset] = useState("");
  const [addingSubSystem, setAddingSubSystem] = useState(false);
  const [newSubSystem, setNewSubSystem] = useState("");
  const [addingSystem, setAddingSystem] = useState(false);
  const [newSystem, setNewSystem] = useState("");
  const [newBuilding, setNewBuilding] = useState(null);
  const { buildings } = useBuildings();

  const openAddSystem = () => {
    if (buildings.length > 0) {
      setNewBuilding(buildings[0]);
    }
    setAddingSystem(true);
  };

  const addAsset = async (asset, type) => {
    const projectId = currentProject.project_id;
    let res;

    if (type === "asset") {
      // assetTypeId appears to always be 'product' for bottom level assets
      const assetTypeId = PRODUCT_TYPE_ID;
      const assetName = newAsset;
      const parentId = asset.id;
      res = await postAsset(assetTypeId, projectId, assetName, parentId);
    }
    if (type === "subSystem") {
      // assetTypeId appears to always be 'subSystem'
      const assetTypeId = SUB_SYSTEM_TYPE_ID;
      const assetName = newSubSystem;
      const parentId = asset.id;
      res = await postAsset(assetTypeId, projectId, assetName, parentId);
    }
    if (type === "system") {
      // assetTypeId appears to always be 'system'
      const assetTypeId = SYSTEM_TYPE_ID;
      const assetName = newSystem;
      const parentId = newBuilding.id;
      res = await postAsset(assetTypeId, projectId, assetName, parentId);
    }
    if (res.status === 201) {
      // Reload systems
      await assembleSystems();
      if (type === "asset") {
        setAddingAsset(false);
        setNewAsset("");
      }
      if (type === "subSystem") {
        setAddingSubSystem(false);
        setNewSubSystem("");
        setCurrentSubSystemId(res?.data?.assets[0].id);
        setCurrentParameterGroups();
        setSelectedTemplate();
      }
      if (type === "system") {
        setAddingSystem(false);
        setNewSystem("");
      }
    }
  };

  return {
    toggleSystem,
    toggleSubSystem,
    addingAsset,
    setAddingAsset,
    addAsset,
    newAsset,
    setNewAsset,
    addingSubSystem,
    setAddingSubSystem,
    newSubSystem,
    setNewSubSystem,
    addingSystem,
    setAddingSystem,
    newSystem,
    setNewSystem,
    openAddSystem,
    newBuilding,
    setNewBuilding,
  };
};

export default useNavigationContent;
