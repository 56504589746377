import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import {
  projectReducer,
  currentProjectReducer,
  currentProjectParametersReducer,
  systemsReducer,
  buildingsReducer,
} from "./projectSlices";

export const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    currentProject: currentProjectReducer,
    currentProjectParameters: currentProjectParametersReducer,
    systems: systemsReducer,
    buildings: buildingsReducer,
  },
});
