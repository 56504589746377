import { PropTypes } from "prop-types";

// Material UI Components
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";

// Hooks
import useRejectModal from "./useRejectModal";

function RejectModal(props) {
  const { currentParameter, rejectModalOpen, setRejectModalOpen } = props;
  const { comment, setComment, handleReject, loading } =
    useRejectModal(setRejectModalOpen);

  return (
    <Modal open={rejectModalOpen} onClose={() => setRejectModalOpen(false)}>
      <Box
        sx={{
          backgroundColor: "white",
          width: 500,
          maxHeight: 300,
          borderRadius: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 2,
          py: 2,
        }}
      >
        <Typography variant="h6">Add a Comment</Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              py: 5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "primary.dark" }} />
          </Box>
        ) : (
          <TextField
            data-cy="popup-reject-box-textbox"
            required
            label="Comment"
            multiline
            rows={4}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            sx={{ width: "100%", mt: 1 }}
          />
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 1 }}
        >
          <Grid item>
            <Button
              sx={{ color: "black" }}
              onClick={() => setRejectModalOpen(false)}
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-cy="popup-reject-box-reject"
              sx={{ color: "error.main" }}
              onClick={() => handleReject(currentParameter.id)}
              disabled={loading}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

RejectModal.propTypes = {
  currentParameter: PropTypes.object,
  rejectModalOpen: PropTypes.bool,
  setRejectModalOpen: PropTypes.func,
};

export default RejectModal;
