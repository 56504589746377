// React
import { useEffect, useState, useRef } from "react";

// Material UI
import { Drawer as MuiDrawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useDetails = (drawerRef, paperRef) => {
  const [open, setOpen] = useState(true);
  const defaultTheme = useTheme();
  const smallScreen = useMediaQuery(defaultTheme.breakpoints.down("md"));
  const [isParameterSelected, setIsParameterSelected] = useState(false);

  useEffect(() => {
    if (isParameterSelected || !smallScreen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isParameterSelected, smallScreen]);

  const smallScreenBack = () => {
    setOpen(false);
    setIsParameterSelected(false);
  };

  let drawerWidth = useRef(
    window.innerWidth < 1100
      ? window.innerWidth
      : Math.round(window.innerWidth / 3)
  );

  const openedMixin = (theme) => ({
    width: drawerWidth.current,
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth.current,
    flexShrink: 0,
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  function handleMouseDown() {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleMouseUp() {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }

  function handleMouseMove(e) {
    const width = window.innerWidth - e.clientX + 5;
    if (width >= 400) {
      drawerWidth.current = parseInt(width) + "px";
      drawerRef.current.style.width = drawerWidth.current;
      paperRef.current.style.width = drawerWidth.current;
    }
  }

  return { Drawer, handleMouseDown, open, smallScreenBack, smallScreen };
};

export default useDetails;
