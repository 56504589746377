// MUI
import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

// Utils
import {
  displayParameterStatusIcon,
  displayParameterName,
  displayParameterValue,
  displayParameterParentAsset,
  displayParameterModificationInfo,
} from "../../../utils/parameterDisplayDetails";

// Other library imports
import PropTypes from "prop-types";

function ParameterItem(props) {
  const parameter = props.parameter;
  const parameterStatusIcon = displayParameterStatusIcon(parameter);
  const parameterName = displayParameterName(parameter);
  const parameterValue = displayParameterValue(parameter);
  const parameterParentAsset = displayParameterParentAsset(parameter);
  const parameterModificationInfo = displayParameterModificationInfo(parameter);

  return (
    <Box data-cy="parameter-detail-button">
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            padding: "0.5rem 1rem 0.5em 1rem",
          }}
          onClick={() => {
            props.setCurrentParameter(parameter);
          }}
        >
          <Tooltip
            data-cy="icon-status"
            title={
              parameter.revision ? parameter.revision.status : "unanswered"
            }
          >
            <ListItemIcon>{parameterStatusIcon}</ListItemIcon>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              maxWidth: 500,
              justifyContent: "space-between",
            }}
          >
            <ListItemText
              primary={parameterName}
              secondary={parameterValue}
            ></ListItemText>
            <ListItemText
              primary={parameterParentAsset}
              secondary={parameterModificationInfo}
              sx={{ textAlign: "right" }}
              primaryTypographyProps={{
                sx: { fontSize: "0.875rem", marginTop: "0.125rem" },
              }}
            ></ListItemText>
          </Box>
        </ListItemButton>
      </ListItem>
      <Divider />
    </Box>
  );
}

ParameterItem.propTypes = {
  parameter: PropTypes.object.isRequired,
  setCurrentParameter: PropTypes.func.isRequired,
};

export default ParameterItem;
