import PropTypes from "prop-types";

//Material UI Components
import { Box, Typography } from "@mui/material";
import { Article } from "@mui/icons-material";

function EmptyState(props) {
  const { title, description } = props;

  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Resize the font/icon size for appropriate screen sizes */}
      <Article fontSize="large" />
      <Typography variant="h6" data-cy="h6-no-param-check">
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default EmptyState;
