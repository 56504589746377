// Material UI Components
import { Add, Check, Close } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";

// Prop types
import PropTypes from "prop-types";

// React
import { useState } from "react";

export default function AddAsset(props) {
  const {
    addingAsset,
    setAddingAsset,
    newAsset,
    setNewAsset,
    addAsset,
    subSystem,
  } = props;

  const [loading, setLoading] = useState(false);

  return (
    <ListItem data-cy="nav-new-type" sx={{ paddingTop: 0, paddingBottom: 0 }}>
      {!addingAsset ? (
        <ListItemButton
          sx={{ pl: 4, pr: 4 }}
          onClick={() => setAddingAsset(true)}
        >
          <ListItemText primary="Add new type" sx={{ color: "primary.dark" }} />
          <ListItemIcon sx={{ minWidth: 24 }}>
            <Add sx={{ color: "primary.dark" }} />
          </ListItemIcon>
        </ListItemButton>
      ) : (
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ pl: 4, pt: "4px", pb: "4px" }}
          spacing={1}
        >
          <Grid item xs={10}>
            <TextField
              autoFocus
              variant="outlined"
              size="small"
              value={newAsset}
              label="Type"
              onChange={(e) => {
                setNewAsset(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!loading ? (
                      <Tooltip title="Add type">
                        {/* wrapper needed for disabled button in Tooltip */}
                        <span>
                          <IconButton
                            data-cy="nav-new-type-add-button"
                            disabled={!newAsset}
                            onClick={async () => {
                              setLoading(true);
                              await addAsset(subSystem, "asset");
                              setLoading(false);
                            }}
                          >
                            <Check
                              sx={
                                newAsset
                                  ? { color: "primary.dark" }
                                  : { color: "none" }
                              }
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Cancel">
              <IconButton onClick={() => setAddingAsset(false)}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
}

AddAsset.propTypes = {
  addingAsset: PropTypes.bool,
  setAddingAsset: PropTypes.func,
  newAsset: PropTypes.string,
  setNewAsset: PropTypes.func,
  addAsset: PropTypes.func,
  subSystem: PropTypes.object,
};
