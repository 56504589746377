// React
import { useEffect, useState } from "react";

// API
import { getParameterBlacklist } from "../../api/eds/parameterBlacklist";

const useBlacklistFilter = (detailsContext) => {
  const [blackList, setBlacklist] = useState([]);

  useEffect(() => {
    async function getData() {
      const getBlacklist = await getParameterBlacklist(
        detailsContext.currentSubSystemId
      );
      const blackListedParams = getBlacklist.data.map(
        (p) => p.parameter_type_id
      );
      setBlacklist(blackListedParams);
    }
    getData();
  }, [detailsContext, setBlacklist]);

  return { blackList };
};

export default useBlacklistFilter;
