import axios from "axios";

import { BASE_URL } from "./index";

import { getDdbToken } from "../../auth/auth";

export const getComment = async (commentId) => {
  const token = await getDdbToken();
  const comment = axios
    .get(`${BASE_URL}/comments/${commentId}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return comment;
};
