import { ListSubheader, MenuItem } from "@mui/material";

export const renderUnitGroup = (unitSystem) => {
  const units = unitSystem.units.map((unit) => (
    <MenuItem value={unit} key={unit.id} sx={{ pl: 4 }}>
      {unit.name}
    </MenuItem>
  ));
  return [
    <ListSubheader key={unitSystem.id} sx={{ color: "info.main" }}>
      {unitSystem.short_name}
    </ListSubheader>,
    units,
  ];
};
