// React
import { useCallback } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCurrentProjectParameters } from "../redux-store/projectSlices";

// API
import {
  getParameters,
  getSelectedParameters,
} from "../api/ddb/parameterService";

function useParameters() {
  const dispatch = useDispatch();

  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const getAllParameters = useCallback(async () => {
    dispatch(setCurrentProjectParameters());
    const parameters = await getParameters(currentProject.project_id);
    dispatch(setCurrentProjectParameters(parameters));
    return parameters;
  }, [dispatch, currentProject]);

  const getSomeParameters = async (assetIds) => {
    dispatch(setCurrentProjectParameters());
    if (assetIds.length > 0) {
      const parameters = await getSelectedParameters(
        currentProject.project_id,
        assetIds
      );
      dispatch(setCurrentProjectParameters(parameters));
      return parameters;
    } else {
      dispatch(setCurrentProjectParameters([]));
    }
  };

  return {
    getAllParameters,
    getSomeParameters,
  };
}

export default useParameters;
