// MUI
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Prop types
import PropTypes from "prop-types";

// API
import { deleteParameter } from "../../../../api/ddb/parameterService";

// Context
import { DetailsContext } from "../../../../contexts/detailsContext";

// React
import { useState, useContext } from "react";

// Hooks
import useParameters from "../../../../hooks/useParameters";
import useGetParameterGroups from "../../../../hooks/useGetParameterGroups";
import useGetSystems from "../../../../hooks/useGetSystems";

function DeleteParameter(props) {
  const { currentParameter, open, onClose } = props;
  const [loading, setLoading] = useState(false);
  const detailsContext = useContext(DetailsContext);
  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const setCurrentParameter = detailsContext.setCurrentParameter;
  const { getAllParameters } = useParameters();
  const { getParameterGroups } = useGetParameterGroups();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Delete Parameter`}</DialogTitle>
      <DialogContent sx={{ pt: "6px !important" }}>
        <DialogContentText>
          Are you sure you want to delete this parameter?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            color: !loading ? "secondary.main" : "secondary.light",
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        {!loading ? (
          <Button
            onClick={async () => {
              setLoading(true);
              await deleteParameter(currentParameter.id);
              await setCurrentParameter();
              setLoading(false);
              if (currentSubSystem) {
                getParameterGroups();
              } else {
                getAllParameters();
              }
              onClose();
            }}
            color="error"
          >
            Delete
          </Button>
        ) : (
          <Box sx={{ width: 69 }}>
            <CircularProgress
              size={20}
              sx={{
                position: "absolute",
                bottom: 16,
                right: 30,
              }}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}

DeleteParameter.propTypes = {
  currentParameter: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteParameter;
