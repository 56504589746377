import axios from "axios";

import { BASE_URL, getHeaders } from "./index";

export const getParameterNotes = async (parameterId) => {
  const parameterNotes = await axios.get(
    `${BASE_URL}/parameter-notes/${parameterId}`,
    { headers: await getHeaders() }
  );
  return parameterNotes;
};

export const deleteParameterNotes = async (id) => {
  const res = await axios.delete(`${BASE_URL}/parameter-notes/${id}`, {
    headers: await getHeaders(),
  });
  return res;
};

export const addParameterNote = async (parameterId, description) => {
  const res = await axios
    .post(
      `${BASE_URL}/parameter-notes`,
      {
        parameter_id: parameterId,
        created_at: new Date(),
        description: description,
      },
      { headers: await getHeaders() }
    )
    .then((res) => {
      return res;
    });
  return res;
};
