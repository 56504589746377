// DDB URL for API calls
export const BASE_URL = "https://sandbox.ddb.arup.com/api";

// Common asset_type_ids
export const SYSTEM_TYPE_ID = "577cfd8d-8da0-4d78-b4a4-c81ab728d4bf";
export const SUB_SYSTEM_TYPE_ID = "e4b51a06-4f87-4108-9fac-fe44182621fb";
export const PRODUCT_TYPE_ID = "49202b68-1ff9-489b-a8ff-9e7d890f1bf4";
export const BUILDING_TYPE_ID = "dbeac84d-9235-47fb-ae08-c8d47e00f253";
export const EDS_UI_SOURCE_ID = "70095161-ba57-4f1c-b60b-77158e40c95f";
// export const queryBuilder = () => {};
