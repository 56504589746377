import axios from "axios";
import { BASE_URL } from "./index";
import { getDdbToken } from "../../auth/auth";

export const getRecords = async (referenceId) => {
  const token = await getDdbToken();
  const revisions = await axios
    .get(`${BASE_URL}/records/?reference_id=${referenceId}`, {
      headers: {
        Authorization: token,
        Version: "0",
      },
    })
    .then((res) => res.data);
  return revisions;
};
