function projectDisplayName(project) {
  return (
    project.job_number.padStart(6, "0") +
    "-" +
    project.job_suffix +
    ": " +
    project.job_name_short
  );
}

export default projectDisplayName;
