import axios from "axios";
import { BASE_URL, getHeaders } from "./index";

export const getNotesByAssetId = async (assetId) => {
  const notes = await axios.get(`${BASE_URL}/eds-notes/${assetId}`, {
    headers: await getHeaders(),
  });
  return notes.data;
};

export const postNote = async (assetId, title, description) => {
  const res = await axios
    .post(
      `${BASE_URL}/eds-notes`,
      {
        asset_id: assetId,
        title: title,
        description: description,
      },
      { headers: await getHeaders() }
    )
    .then((res) => {
      return res;
    });
  return res;
};

export const deleteNote = async (noteId) => {
  const res = await axios.delete(`${BASE_URL}/eds-notes/${noteId}`, {
    headers: await getHeaders(),
  });
  return res;
};

export const patchNote = async (noteId, newDescription) => {
  const res = await axios
    .patch(
      `${BASE_URL}/eds-notes/${noteId}`,
      {
        description: newDescription,
      },
      { headers: await getHeaders() }
    )
    .then((res) => {
      return res;
    });
  return res;
};
