import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const useHooks = () => {
  const useIsWidthUp = (breakpoint) => {
    const defaultTheme = useTheme();
    return useMediaQuery(defaultTheme.breakpoints.up(breakpoint));
  };
  return useIsWidthUp;
};

export default useHooks;
