import PropTypes from "prop-types";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { deleteAsset, renameAsset } from "../../../api/ddb/parameterService";
import useSystems from "../../../hooks/useSystems";

function EditAsset(props) {
  const { open, onClose, editingAsset } = props;
  const { assembleSystems } = useSystems();
  const [textValue, setTextValue] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (editingAsset) {
      setTextValue(editingAsset.name);
    }
  }, [editingAsset]);

  return (
    <>
      {editingAsset ? (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>{`Editing ${editingAsset.asset_type.name}`}</DialogTitle>
          <DialogContent sx={{ paddingTop: "6px !important" }}>
            <TextField
              data-cy="nav-system-edit-rename"
              required
              label="Name"
              value={textValue}
              disabled={loadingSubmit || loadingDelete}
              onChange={(e) => {
                setTextValue(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box sx={{ flexGrow: 1 }}>
              {!loadingDelete ? (
                <>
                  {editingAsset.children.length === 0 ? (
                    <Button
                      data-cy="nav-system-edit-delete"
                      sx={{
                        color: !loadingSubmit
                          ? "error.main"
                          : "secondary.light",
                      }}
                      disabled={loadingSubmit}
                      onClick={async () => {
                        setLoadingDelete(true);
                        try {
                          await deleteAsset(editingAsset.id);
                        } catch {
                          // empty catch block as the response is a 404
                          // this is a known issue with the DDB endpoint
                        }
                        setLoadingDelete(false);
                        assembleSystems();
                        onClose();
                      }}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Tooltip
                      describeChild
                      followCursor={true}
                      title="Assets with children cannot be deleted"
                    >
                      <span>
                        <Button sx={{ color: "secondary.light" }} disabled>
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </>
              ) : (
                <CircularProgress
                  size={20}
                  color="error"
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    left: 30,
                  }}
                />
              )}
            </Box>
            <Button
              sx={{
                color: !loadingSubmit ? "secondary.main" : "secondary.light",
              }}
              disabled={loadingSubmit || loadingDelete}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {!loadingSubmit ? (
              <Button
                data-cy="nav-system-edit-submit"
                sx={{
                  color: !loadingDelete ? "primary.main" : "secondary.light",
                }}
                disabled={loadingDelete}
                onClick={async () => {
                  setLoadingSubmit(true);
                  await renameAsset(editingAsset.id, textValue);
                  setLoadingSubmit(false);
                  assembleSystems();
                  onClose();
                }}
              >
                Submit
              </Button>
            ) : (
              <Box sx={{ width: 69 }}>
                <CircularProgress
                  size={20}
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 30,
                  }}
                />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

EditAsset.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editingAsset: PropTypes.object,
};

export default EditAsset;
