import axios from "axios";
import { BASE_URL, getHeaders } from "./index";

export const getQaStatusByAssetId = async (assetId) => {
  const qaStatus = await axios.get(`${BASE_URL}/eds-qa/asset/${assetId}`, {
    headers: await getHeaders(),
  });
  return qaStatus.data;
};

export const postQaStatus = async (
  noteId,
  assetId,
  status,
  createdBy,
  createdAt,
  comment
) => {
  const res = await axios
    .post(
      `${BASE_URL}/eds-qa`,
      {
        note_id: noteId,
        asset_id: assetId,
        status: status,
        created_by_name: createdBy,
        created_at: createdAt,
        comment: comment,
      },
      { headers: await getHeaders() }
    )
    .then((res) => {
      return res;
    });
  return res;
};

export const postMultipleQaStatus = async (qaArray) => {
  const res = await axios
    .post(`${BASE_URL}/eds-qa`, qaArray, { headers: await getHeaders() })
    .then((res) => {
      return res;
    });
  return res;
};

export const deleteQaByNoteId = async (noteId) => {
  const res = await axios
    .delete(`${BASE_URL}/eds-qa/noteId/${noteId}`, {
      headers: await getHeaders(),
    })
    .then((res) => {
      return res;
    });
  return res;
};
