import { createSlice } from "@reduxjs/toolkit";

export const projectSlice = createSlice({
  name: "projects",
  initialState: {},
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
  },
});

export const currentProjectSlice = createSlice({
  name: "currentProject",
  initialState: {},
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
  },
});

export const currentProjectParametersSlice = createSlice({
  name: "currentProjectParameters",
  initialState: {},
  reducers: {
    setCurrentProjectParameters: (state, action) => {
      state.currentProjectParameters = action.payload;
    },
  },
});

export const systemsSlice = createSlice({
  name: "systems",
  initialState: {},
  reducers: {
    setSystems: (state, action) => {
      state.systems = action.payload;
    },
  },
});

export const buildingsSlice = createSlice({
  name: "buildings",
  initialState: {},
  reducers: {
    setBuildings: (state, action) => {
      state.buildings = action.payload;
    },
  },
});

export const { setProjects } = projectSlice.actions;
export const { setCurrentProject } = currentProjectSlice.actions;
export const { setCurrentProjectParameters } =
  currentProjectParametersSlice.actions;
export const { setSystems } = systemsSlice.actions;
export const { setBuildings } = buildingsSlice.actions;

const projectReducer = projectSlice.reducer;
const currentProjectReducer = currentProjectSlice.reducer;
const currentProjectParametersReducer = currentProjectParametersSlice.reducer;
const systemsReducer = systemsSlice.reducer;
const buildingsReducer = buildingsSlice.reducer;

export {
  projectReducer,
  currentProjectReducer,
  currentProjectParametersReducer,
  systemsReducer,
  buildingsReducer,
};
