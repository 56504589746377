function getSystemAssetIDs(system) {
  const assetIDs = [];
  system.subSystems.forEach((subSystem) => {
    subSystem.assets.forEach((asset) => {
      assetIDs.push(asset.id);
    });
  });
  return assetIDs;
}

export { getSystemAssetIDs };
