import axios from "axios";
import { Buffer } from "buffer";

const BASE_URL = "https://graph.microsoft.com/v1.0";

export const getAvatar = async (token) => {
  const uniqueString = new Date().toString();
  try {
    const response = await axios.get(
      `${BASE_URL}/me/photo/$value?${uniqueString}`,
      {
        cache: true,
        responseType: "arraybuffer",
        headers: { Authorization: token },
      }
    );
    const base64 = Buffer.from(response.data, "binary").toString("base64");
    return `data:image/jpeg;base64,${base64}`;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const getUserInformation = async (token) => {
  const uniqueString = new Date().toString();
  const response = await axios.get(`${BASE_URL}/me?${uniqueString}`, {
    cache: true,
    headers: { Authorization: token },
  });
  return response.data;
};
