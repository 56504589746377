import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";

//Redux
import { store } from "./redux-store/store";
import { Provider } from "react-redux";

//Material UI Components
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";

//MSAL
import { MsalProvider } from "@azure/msal-react";
import msalInstance from "./auth/auth-provider";

//Routes
import Home from "./routes/home";
import Projects from "./routes/projects";
import Details from "./routes/details";

//Providers
import UserProvider from "./providers/userProvider";
import ProtectedPage from "./providers/protectedPage";

//Components
import Header from "./components/header/header";
import ApiError from "./components/apiError/apiError";

// Context
import { DetailsProvider } from "./contexts/detailsContext";
import { SocketProvider } from "./contexts/socketContext";
import { ApiErrorProvider } from "./contexts/apiErrorContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <SocketProvider>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <ApiErrorProvider>
                <ApiError />
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/projects"
                    element={
                      <ProtectedPage>
                        <Projects />
                      </ProtectedPage>
                    }
                  />
                  <Route
                    path="/projects/:projectId/details"
                    element={
                      <DetailsProvider>
                        <Details />
                      </DetailsProvider>
                    }
                  />
                </Routes>
              </ApiErrorProvider>
            </ThemeProvider>
          </UserProvider>
        </SocketProvider>
      </Provider>
    </MsalProvider>
  </Router>
);
