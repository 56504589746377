import { useState, useEffect, useCallback } from "react";

// API
import {
  getParameterNotes,
  deleteParameterNotes,
  addParameterNote,
} from "../../../../api/eds/parameterNotes";

const useParameterNotes = (currentParameter) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [parameterNotes, setParameterNotes] = useState([]);
  const [addingNewNote, setAddingNewNote] = useState(false);
  const [newParameterNote, setNewParameterNote] = useState("");

  const toggleNotesOpen = () => {
    setNotesOpen(!notesOpen);
  };

  const getNotes = useCallback(async () => {
    const newParameterNotes = await getParameterNotes(currentParameter.id);
    setParameterNotes(newParameterNotes.data);
  }, [currentParameter.id]);

  const removeNotes = async (note) => {
    const res = await deleteParameterNotes(note.id);
    if (res.status === 200) {
      getNotes();
    }
  };

  const postNote = async () => {
    const res = await addParameterNote(currentParameter.id, newParameterNote);
    if (res.status === 201) {
      setAddingNewNote(false);
      setNewParameterNote("");
      getNotes();
    }
  };

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return {
    notesOpen,
    toggleNotesOpen,
    parameterNotes,
    removeNotes,
    addingNewNote,
    setAddingNewNote,
    newParameterNote,
    setNewParameterNote,
    postNote,
  };
};

export default useParameterNotes;
