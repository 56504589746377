import PropTypes from "prop-types";

// Context
import { useContext } from "react";
import { DetailsContext } from "../../../../contexts/detailsContext";

// Material UI Components
import {
  Box,
  Grid,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  Alert,
  Skeleton,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  FileCopy,
  Subject,
  History,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckCircleOutline,
  PendingOutlined,
  DangerousOutlined,
  Edit,
  ArrowBack,
  Check,
  Close,
  Delete,
} from "@mui/icons-material";

// Utils
import selectIcon from "../../../../utils/selectIcon";
import formatDate from "../../../../utils/formatDate";

// Hooks
import useEdsNoteContent from "./useEdsNoteContent";
import useGetSystems from "../../../../hooks/useGetSystems";

// Project Components
import RejectNoteModal from "./rejectNoteModal/rejectNoteModal";

function EdsNoteContent(props) {
  const { smallScreen, smallScreenBack } = props;
  const detailsContext = useContext(DetailsContext);
  const currentNote = detailsContext.currentNote;
  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();
  const {
    statusHistoryOpen,
    toggleStatusHistoryOpen,
    handleCheckApprove,
    rejectModalOpen,
    setRejectModalOpen,
    editingNote,
    setEditingNote,
    newDescription,
    setNewDescription,
    loading,
    setLoading,
    updateDescription,
    removeNote,
  } = useEdsNoteContent();

  return (
    <Box>
      <Grid
        container
        id="header"
        direction="row"
        alignItems="center"
        sx={{ paddingTop: 2, paddingBottom: 2 }}
      >
        <Grid item xs={12} sm={8}>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
                height: "35px",
              }}
            >
              <Tooltip title={currentNote.qaData[0]?.status}>
                {selectIcon(currentNote.qaData[0]?.status)}
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography variant="h6">{currentNote.title}</Typography>
              <Typography variant="subtitle1">
                {currentSubSystem?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ float: "right", paddingRight: 3 }}>
            {smallScreen ? (
              <IconButton onClick={() => smallScreenBack()}>
                <ArrowBack sx={{ color: "primary.dark" }} />
              </IconButton>
            ) : null}
            <Tooltip title="copy">
              <IconButton
                disabled={editingNote}
                onClick={() => navigator.clipboard.writeText(currentNote.id)}
              >
                <FileCopy
                  sx={!editingNote ? { color: "primary.dark" } : null}
                />
              </IconButton>
            </Tooltip>
            {!editingNote ? (
              <Tooltip title="edit">
                <IconButton onClick={() => setEditingNote(true)}>
                  <Edit sx={{ color: "primary.dark" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="cancel">
                <IconButton
                  sx={{ color: "primary.dark" }}
                  onClick={() => setEditingNote(false)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="delete">
              <IconButton
                sx={{ color: "primary.dark" }}
                onClick={() => removeNote(currentNote.id)}
              >
                <Delete sx={!editingNote ? { color: "primary.dark" } : null} />
              </IconButton>
            </Tooltip>
            {currentNote.qaData[0]?.status !== "rejected" ? (
              <Tooltip title="reject">
                <IconButton
                  onClick={() => setRejectModalOpen(true)}
                  disabled={editingNote}
                >
                  <DangerousOutlined
                    sx={!editingNote ? { color: "primary.dark" } : null}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
            {currentNote.qaData[0]?.status === "answered" ? (
              <Tooltip title="check">
                <IconButton
                  onClick={() => handleCheckApprove("checked")}
                  disabled={editingNote}
                >
                  <PendingOutlined
                    sx={!editingNote ? { color: "primary.dark" } : null}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
            {currentNote.qaData[0]?.status === "checked" ? (
              <Tooltip title="approve">
                <IconButton
                  onClick={() => handleCheckApprove("approved")}
                  disabled={editingNote}
                >
                  <CheckCircleOutline
                    sx={!editingNote ? { color: "primary.dark" } : null}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Divider />
      {currentNote.qaData[0]?.status === "rejected" ? (
        <Alert severity="error">
          {currentNote.qaData[0]?.comment ? (
            <>
              <Typography sx={{ fontWeight: "bold" }}>
                Rejected by {currentNote.qaData[0]?.created_by_name} on{" "}
                {formatDate(currentNote.qaData[0]?.created_at)}
              </Typography>
              <Typography>Comment: {currentNote.qaData[0]?.comment}</Typography>
            </>
          ) : (
            <>
              <Skeleton sx={{ width: 200 }} />
              <Skeleton sx={{ width: 200 }} />
            </>
          )}
        </Alert>
      ) : null}
      <Box id="content" sx={{ paddingLeft: 4 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ paddingTop: 5 }}
        >
          <Grid item sx={{ height: "24px" }}>
            <Subject fontSize="medium" />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              gutterBottom
              display="inline"
              sx={{ paddingLeft: 2 }}
            >
              Description
            </Typography>
          </Grid>
        </Grid>
        {!editingNote ? (
          <Typography variant="body2" sx={{ paddingLeft: 5, paddingTop: 1 }}>
            {currentNote.description}
          </Typography>
        ) : (
          <Box sx={{ pt: 1, pl: 5 }}>
            <TextField
              autoFocus
              multiline
              rows={3}
              variant="outlined"
              size="small"
              value={newDescription}
              label="Description"
              onChange={(e) => {
                setNewDescription(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ alignItems: "flex-end" }}
                  >
                    {!loading ? (
                      <Tooltip title="Update Description">
                        {/* wrapper needed for disabled button in Tooltip */}
                        <span>
                          <IconButton
                            disabled={
                              newDescription === currentNote.description
                            }
                            onClick={async () => {
                              setLoading(true);
                              await updateDescription();
                              setLoading(false);
                            }}
                          >
                            <Check
                              sx={
                                newDescription !== currentNote.description
                                  ? { color: "primary.dark" }
                                  : { color: "none" }
                              }
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        <Box id="statusHistory">
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ paddingTop: 5 }}
          >
            <Grid item sx={{ height: "24px" }}>
              <History fontSize="medium" />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                gutterBottom
                display="inline"
                sx={{ paddingLeft: 2 }}
              >
                Status History
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            onClick={() => {
              toggleStatusHistoryOpen();
            }}
            sx={{ ml: 3.5 }}
          >
            {statusHistoryOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <Typography variant="body2" display="inline">
            {currentNote.qaData[0]?.status.charAt(0).toUpperCase() +
              currentNote.qaData[0]?.status.slice(1) +
              " "}
            by {currentNote.qaData[0]?.created_by_name + " "}
            on {formatDate(currentNote.qaData[0]?.created_at)}
          </Typography>
          <Collapse in={statusHistoryOpen}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Updated by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentNote.qaData ? (
                  currentNote.qaData.map((rec) => (
                    <TableRow key={rec.id}>
                      <TableCell>
                        <Tooltip title={rec.status || "undefined"}>
                          {selectIcon(rec.status, "medium")}
                        </Tooltip>
                      </TableCell>
                      <TableCell>{formatDate(rec.created_at)}</TableCell>
                      <TableCell>{rec.created_by_name}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Skeleton>
                        <CheckCircleOutline />
                      </Skeleton>
                    </TableCell>
                    <TableCell>
                      <Skeleton>
                        <Typography variant="body1">00/00/0000</Typography>
                      </Skeleton>
                    </TableCell>
                    <TableCell>
                      <Skeleton>
                        <Typography variant="body1">First Last</Typography>
                      </Skeleton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </Box>
      </Box>
      {rejectModalOpen ? (
        <RejectNoteModal
          rejectModalOpen={rejectModalOpen}
          setRejectModalOpen={setRejectModalOpen}
        />
      ) : null}
    </Box>
  );
}

EdsNoteContent.propTypes = {
  smallScreenBack: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool.isRequired,
};

export default EdsNoteContent;
