// Material UI Components
import { ChevronRightOutlined, ChevronLeftOutlined } from "@mui/icons-material";
import { Divider, IconButton, Box } from "@mui/material";

import PropTypes from "prop-types";

function NavigationFooter(props) {
  const { toggleDrawer, open } = props;
  return (
    <Box textAlign={open ? "right" : "center"} sx={{ width: "100%" }}>
      <Divider />
      <IconButton
        onClick={() => toggleDrawer()}
        data-cy="navigation-min-button"
      >
        {open ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
      </IconButton>
    </Box>
  );
}

NavigationFooter.propTypes = {
  toggleDrawer: PropTypes.func,
  open: PropTypes.bool,
};

export default NavigationFooter;
