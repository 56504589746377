// Redux
import { useSelector } from "react-redux";

// Utils
import projectDisplayName from "../../../utils/projectDisplayName";

// Material UI Components
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Prop Types
import PropTypes from "prop-types";

//React
import { useState, useEffect } from "react";

function NavigationHeader(props) {
  const { open } = props;
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );
  const [currentProjectDisplayName, setcurrentProjectDisplayName] =
    useState(null);

  useEffect(() => {
    if (currentProject) {
      setcurrentProjectDisplayName(projectDisplayName(currentProject));
    }
  }, [currentProject]);

  return (
    <>
      {currentProjectDisplayName ? (
        <>
          <Grid
            container
            alignItems="center"
            direction={open ? "row" : "column"}
            justify="space-between"
            sx={{ padding: "1.1rem 0 1rem 0" }}
          >
            <Grid item xs={open ? 10 : true}>
              {open ? (
                <Typography
                  variant="h6"
                  sx={{ paddingLeft: "1rem" }}
                  data-cy="navigation-h6-header"
                >
                  {currentProjectDisplayName}
                </Typography>
              ) : (
                <Tooltip title={currentProjectDisplayName} placement="right">
                  <Avatar variant="rounded" data-cy="avatar">
                    {currentProjectDisplayName.match(/\b(\w)/g).slice(2, 4)}
                  </Avatar>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}

NavigationHeader.propTypes = {
  open: PropTypes.bool,
};

export default NavigationHeader;
