// Material UI Components
import { Box, Alert, Paper, Typography } from "@mui/material";

// Utils
import useCurrentProject from "../../hooks/useCurrentProject";

// Project Components
import DocumentTitle from "../documentTitle/documentTitle";
import PrintPreview from "../printPreview/printPreview";
import PrintHeader from "./printHeader/printHeader";

// API
import { postPrint } from "../../api/eds/printService";

// React
import { useState } from "react";

// Hooks
import useGetSystems from "../../hooks/useGetSystems";

export default function Print() {
  const [headerData, setHeaderData] = useState({});

  const currentProject = useCurrentProject();

  const { getCurrentSubSystem } = useGetSystems();
  const subSystem = getCurrentSubSystem();

  const [formValid, setFormValid] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  const printHandler = async () => {
    if (formValid) {
      const printContent = document.querySelector("#printBox");
      const html = printContent.innerHTML;
      const strippedHtml = html.replaceAll(`"`, `'`);
      const printData = {
        html: strippedHtml,
        project_name: currentProject.currentProject.job_name_short,
        project_id: currentProject.currentProject.project_id,
        subsystem_name: subSystem.name,
        asset_id: subSystem.id,
        header_data: headerData,
      };

      setDisplayWarning(false);
      await postPrint(printData);
    } else {
      setDisplayWarning(true);
    }
  };

  return (
    <>
      {currentProject ? (
        <>
          <Box
            width="100%"
            sx={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <PrintHeader printHandler={printHandler} />
            <Box sx={{ flex: 1, overflowY: "scroll" }}>
              <Typography sx={{ margin: "20px 0px 0px 15px" }} variant="h6">
                Cover Page Details (please complete)
              </Typography>

              <DocumentTitle
                setHeaderData={setHeaderData}
                setFormValid={setFormValid}
              />

              {displayWarning && (
                <Alert severity="error">
                  Please complete all mandatory fields!
                </Alert>
              )}

              <Typography
                sx={{
                  margin: "20px 15px 30px 15px",
                  paddingTop: "20px",
                  borderTop: "1px solid lightgray",
                }}
                variant="h6"
              >
                Print Preview
              </Typography>

              <Paper elevation={3} sx={{ margin: "0 5% 25px 5%" }}>
                <PrintPreview />
              </Paper>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
}
