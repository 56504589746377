// Material UI Components
import {
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import { Print, ArrowLeft } from "@mui/icons-material";

// React
import { useState, useContext } from "react";

// Prop types
import PropTypes from "prop-types";

// Context
import { DetailsContext } from "../../../contexts/detailsContext";

// Hooks
import useGetSystems from "../../../hooks/useGetSystems";

function PrintHeader(props) {
  const { printHandler } = props;
  const detailsContext = useContext(DetailsContext);

  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const [isPrinting, setIsPrinting] = useState(false);
  const setShowPrintPreview = detailsContext.setShowPrintPreview;

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ borderBottom: 2, borderColor: "secondary.light" }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Button
                onClick={() => setShowPrintPreview(false)}
                startIcon={<ArrowLeft />}
              >
                <Typography>Back to Grouping</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>
                Print Preview: {currentSubSystem.name}
              </Typography>
            </Grid>
            <Grid item>
              {isPrinting ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={async () => {
                    setIsPrinting(true);
                    await printHandler();
                    setIsPrinting(false);
                  }}
                  endIcon={<Print />}
                >
                  <Typography>Print EDS</Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

PrintHeader.propTypes = {
  printHandler: PropTypes.func.isRequired,
};

export default PrintHeader;
