// Prop types
import PropTypes from "prop-types";

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

// Redux
import { useSelector } from "react-redux";

// API
import { addParameter } from "../../api/ddb/parameterService";

// Utils
import { renderUnitGroup } from "../../utils/renderUnitGroup";

// Hooks
import useParameters from "../../hooks/useParameters";
import useAddParameter from "./useAddParameter";
import useGetParameterGroups from "../../hooks/useGetParameterGroups";
import useGetSystems from "../../hooks/useGetSystems";

function AddParameter(props) {
  const { open, onClose } = props;

  const systems = useSelector((state) => state.systems.systems);
  const currentProject = useSelector(
    (state) => state.currentProject.currentProject
  );

  const { getCurrentSubSystem, getCurrentSystem } = useGetSystems();

  const { getAllParameters } = useParameters();
  const { getParameterGroups } = useGetParameterGroups();
  const currentSubSystem = getCurrentSubSystem();
  const currentSystem = getCurrentSystem();

  const {
    loading,
    setLoading,
    newParameterSystem,
    setNewParameterSystem,
    newParameterSubSystem,
    setNewParameterSubSystem,
    newParameterAsset,
    setNewParameterAsset,
    newParameterType,
    setNewParameterType,
    newParameterTypeOptions,
    newParameterValue,
    setNewParameterValue,
    newParameterUnit,
    setNewParameterUnit,
    newParameterUnitOptions,
  } = useAddParameter(open);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {currentSubSystem ? `Add Parameter Value` : `Add Parameter`}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "6px !important" }}>
        <Box sx={{ mb: 2 }}>
          <Select
            disabled={loading || currentSubSystem || currentSystem}
            size="small"
            value={newParameterSystem}
            onChange={(e) => setNewParameterSystem(e.target.value)}
            // sx={{ width: "40%", maxWidth: 400 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
            sx={{ mr: 1 }}
          >
            <MenuItem value={"Empty"} disabled>
              <em>System</em>
            </MenuItem>
            {systems
              ? systems.map((system) => (
                  <MenuItem value={system} key={system.id}>
                    {system.name}
                  </MenuItem>
                ))
              : null}
          </Select>
          <Select
            disabled={loading || currentSubSystem}
            size="small"
            value={newParameterSubSystem}
            onChange={(e) => setNewParameterSubSystem(e.target.value)}
            // sx={{ width: "40%", maxWidth: 400 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
            sx={{ mr: 1 }}
          >
            <MenuItem value={"Empty"} disabled>
              <em>Subsystem</em>
            </MenuItem>
            {newParameterSystem !== "Empty"
              ? newParameterSystem.subSystems.map((subSystem) => (
                  <MenuItem value={subSystem} key={subSystem.id}>
                    {subSystem.name}
                  </MenuItem>
                ))
              : null}
          </Select>
          <Select
            disabled={loading}
            size="small"
            value={newParameterAsset}
            onChange={(e) => setNewParameterAsset(e.target.value)}
            // sx={{ width: "40%", maxWidth: 400 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
          >
            <MenuItem value={"Empty"} disabled>
              <em>Type</em>
            </MenuItem>
            {newParameterSubSystem !== "Empty"
              ? newParameterSubSystem.assets.map((asset) => (
                  <MenuItem value={asset} key={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </Box>
        <Select
          disabled={loading}
          size="small"
          value={newParameterType}
          onChange={(e) => setNewParameterType(e.target.value)}
          // sx={{ width: "40%", maxWidth: 400 }}
          sx={{ mb: 2 }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
        >
          <MenuItem value={"Empty"} disabled>
            <em>Parameter name</em>
          </MenuItem>
          {newParameterTypeOptions !== "Empty"
            ? newParameterTypeOptions.parameter_types.map((parameter_type) => (
                <MenuItem value={parameter_type} key={parameter_type.id}>
                  {parameter_type.name}
                </MenuItem>
              ))
            : null}
        </Select>
        <Box>
          {newParameterType.data_type === "boolean" ? (
            <Select
              disabled={loading}
              size="small"
              value={newParameterValue}
              onChange={(e) => setNewParameterValue(Boolean(e.target.value))}
              sx={{ width: "40%", maxWidth: 400 }}
            >
              <MenuItem value={"Empty"} disabled>
                <em>Parameter Value</em>
              </MenuItem>
              <MenuItem value={true}>true</MenuItem>
              <MenuItem value={false}>false</MenuItem>
            </Select>
          ) : (
            <TextField
              disabled={loading}
              autoFocus
              variant="outlined"
              size="small"
              value={newParameterValue}
              placeholder="Parameter Value"
              onChange={(e) => {
                setNewParameterValue(e.target.value);
              }}
              sx={{ mr: 1 }}
              // error={isNewParameterValueInvalid}
              // helperText={newParameterHelpText}
              // sx={{ width: "40%", maxWidth: 400 }}
            />
          )}
          {newParameterType === "Empty" || newParameterType.unit_type ? (
            <Select
              disabled={loading}
              size="small"
              value={newParameterUnit}
              onChange={(e) => setNewParameterUnit(e.target.value)}
              // sx={{ width: "40%", maxWidth: 400 }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 570 } } }}
            >
              <MenuItem value={"Empty"} disabled>
                <em>Parameter Unit</em>
              </MenuItem>
              {newParameterUnitOptions !== "Empty"
                ? newParameterUnitOptions.unit_type.unit_systems.map(
                    (unitSystem) => renderUnitGroup(unitSystem)
                  )
                : null}
            </Select>
          ) : null}
        </Box>
        <Typography variant="caption">
          {`All parameters are assigned
        a data source of 'eds_ui'.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            color: !loading ? "secondary.main" : "secondary.light",
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        {!loading ? (
          <Button
            onClick={async () => {
              setLoading(true);
              await addParameter(
                currentProject.project_id,
                newParameterType.id,
                newParameterAsset.id,
                newParameterValue,
                newParameterUnit.id
              );
              setLoading(false);
              if (currentSubSystem) {
                getParameterGroups();
              } else {
                getAllParameters();
              }
              onClose();
            }}
          >
            Add
          </Button>
        ) : (
          <Box sx={{ width: 69 }}>
            <CircularProgress
              size={20}
              sx={{
                position: "absolute",
                bottom: 16,
                right: 30,
              }}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}

AddParameter.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddParameter;
