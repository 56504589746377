// Material UI Components
import { Toolbar, Box } from "@mui/material";

// Hooks
import useNavigation from "./use-hooks";

// Project Components
import NavigationHeader from "./header/navigation-header";
import NavigationContent from "./content/navigation-content";
import NavigationFooter from "./footer/navigation-footer";
import OtherUsers from "./header/other-users";

// React
import { useRef } from "react";

function Navigation() {
  const drawerRef = useRef();
  const paperRef = useRef();

  const { open, toggleDrawer, Drawer, handleMouseDown } = useNavigation(
    drawerRef,
    paperRef
  );

  return (
    <Drawer
      variant="permanent"
      open={open}
      ref={drawerRef}
      PaperProps={{ ref: paperRef }}
    >
      <Toolbar />
      {open ? (
        <Box
          sx={{
            width: "5px",
            height: "100%",
            position: "absolute",
            right: 0,
            cursor: "col-resize",
            zIndex: 2000,
          }}
          onMouseDown={handleMouseDown}
        />
      ) : null}
      <NavigationHeader open={open} />
      <OtherUsers open={open} />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        {open ? <NavigationContent /> : null}
      </Box>
      <NavigationFooter toggleDrawer={toggleDrawer} open={open} />
    </Drawer>
  );
}

export default Navigation;
