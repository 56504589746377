// Context
import { useContext } from "react";
import { DetailsContext } from "../../contexts/detailsContext";

// Project Components
import ParameterPanel from "../../components/parameterPanel/parameterPanel";
import GroupingPanel from "../../components/groupingPanel/groupingPanel";
import Print from "../../components/print";

//Hooks
import useGetSystems from "../../hooks/useGetSystems";

function MiddlePanel() {
  const { getCurrentSubSystem } = useGetSystems();
  const currentSubSystem = getCurrentSubSystem();

  const detailsContext = useContext(DetailsContext);
  const showPrintPreview = detailsContext.showPrintPreview;

  return (
    <>
      {currentSubSystem ? (
        showPrintPreview ? (
          <Print />
        ) : (
          <GroupingPanel />
        )
      ) : (
        <ParameterPanel />
      )}
    </>
  );
}

export default MiddlePanel;
