import { InteractionRequiredAuthError } from "@azure/msal-browser";
import msalInstance, {
  loginRequest,
  ddbTokenRequest,
  edsApiTokenRequest,
} from "./auth-provider";

const tokenRequest = async (request) => {
  let token;
  try {
    const account = await msalInstance.getActiveAccount();
    if (account) {
      token = await msalInstance.acquireTokenSilent({ ...request, account });
    } else {
      token = await msalInstance.acquireTokenRedirect({ ...request, account });
    }
  } catch (error) {
    try {
      if (error instanceof InteractionRequiredAuthError) {
        token = await msalInstance.acquireTokenRedirect(request);
      } else {
        console.error(error);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return token;
};

export const getToken = async () => {
  const token = await tokenRequest(loginRequest);
  if (token) {
    return `Bearer ${token.accessToken}`;
  } else {
    return null;
  }
};

export const getDdbToken = async () => {
  const token = await tokenRequest(ddbTokenRequest);
  if (token) {
    return `Bearer ${token.accessToken}`;
  } else {
    return null;
  }
};

export const getEdsApiToken = async () => {
  const token = await tokenRequest(edsApiTokenRequest);
  if (token) {
    return `Bearer ${token.accessToken}`;
  } else {
    return null;
  }
};
